import { Button, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { api } from 'src/api';
import { ApiContractMethod, ApiContractReadMethodResp } from 'src/types/MultidataApi';
import { ContractInputs } from './ContractInputs';

export const ReadForm = ({
  method,
  contractType,
}: {
  method: ApiContractMethod;
  contractType: string;
}) => {
  const { address } = useParams();
  const theme = useTheme();
  const { register, handleSubmit } = useForm();

  const [resp, setResp] = useState<null | ApiContractReadMethodResp>(null);
  const [isRespLoading, setIsRespLoading] = useState(false);

  const onSubmit = (data: any) => {
    if (!address) return;
    const args = Object.values(data);
    const body = {
      args,
      method_id: method.method_id,
      contract_type: contractType,
    };

    setIsRespLoading(true);
    api.multidata.queryReadMethod(address, body).then((resp: ApiContractReadMethodResp) => {
      setIsRespLoading(false);
      setResp(resp);
    });
  };

  const renderResp = () => {
    if (!resp) return;

    if (isRespLoading) {
      return (
        <Typography fontSize="0.875rem" mt="0.5rem">
          Loading...
        </Typography>
      );
    }

    return resp.is_error ? (
      resp.result.error ? (
        <Typography fontSize="0.875rem" mt="0.5rem" color="error">
          {resp.result.error}
        </Typography>
      ) : (
        <Typography fontSize="0.875rem" mt="0.5rem" color="error">
          {resp.result.code} {resp.result.message} {resp.result.raw}
        </Typography>
      )
    ) : (
      resp.result.output.map((result, index) => (
        <Typography key={index} mt="0.5rem" fontSize="0.875rem">
          {result.type && (
            <Typography
              component="i"
              sx={{ fontSize: '0.825rem', color: `${theme.palette.grey[600]}` }}
            >
              {result.type}:{' '}
            </Typography>
          )}{' '}
          {!!result.value ? result.value : JSON.stringify(result.value)}{' '}
        </Typography>
      ))
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ContractInputs
        inputs={method.inputs}
        lastElementIndex={method.inputs.length - 1}
        register={register}
      />
      <Button variant="contained" type="submit" sx={{ marginTop: '1rem' }}>
        query
      </Button>
      {renderResp()}
    </form>
  );
};
