import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Grid, IconButton, Stack, Typography } from '@mui/material';

import { Iconify } from 'src/components/Iconify';
import RHFTextField from 'src/components/form/RHFTextField';
import { RHFCheckbox } from 'src/components/form/RHFCheckbox';

export const LibrariesField = ({ disabled }: { disabled?: boolean }) => {
  const { control, watch, resetField } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'details.libraries',
  });
  const maxAmount = 10;

  useEffect(() => {
    if (!watch('details.withLibraries')) {
      resetField('details.libraries');
      return;
    }

    handleAdd();
  }, [watch('details.withLibraries')]);

  const handleAdd = () => {
    append({
      name: '',
      address: '',
    });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  return (
    <>
      <Grid item xs={12} md={8}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <RHFCheckbox
            name="details.withLibraries"
            label="Add contract libraries"
            disabled={disabled}
          />
        </Stack>
      </Grid>

      {fields.map((item, index) => (
        <React.Fragment key={item.id}>
          <Grid item xs={12} md={8}>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                Contract library {index + 1}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                {fields.length > 1 && (
                  <IconButton size="small" onClick={() => handleRemove(index)} disabled={disabled}>
                    <Iconify icon="eva:minus-fill" />
                  </IconButton>
                )}
                {fields.length < maxAmount && (
                  <IconButton size="small" onClick={handleAdd} disabled={disabled}>
                    <Iconify icon="eva:plus-fill" />
                  </IconButton>
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <RHFTextField
              name={`details.libraries[${index}].name`}
              label="Library name (.sol file)*"
              disabled={disabled}
            />
          </Grid>
          {index === 0 && (
            <Grid item xs={12} md={4}>
              <Typography
                variant="body2"
                sx={{
                  color: 'text.secondary',
                }}
              >
                A library name called in the .sol file. Multiple libraries (up to {maxAmount}) may
                be added for each contract.
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} md={8}>
            <RHFTextField
              name={`details.libraries[${index}].address`}
              label="Library address (0x...)*"
              disabled={disabled}
            />
          </Grid>
          {index === 0 && (
            <Grid item xs={12} md={4}>
              <Typography
                variant="body2"
                sx={{
                  color: 'text.secondary',
                }}
              >
                The 0x library address. This can be found in the generated json file or Truffle
                output (if using truffle).
              </Typography>
            </Grid>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
