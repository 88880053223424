import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { api } from 'src/api';
import { CONFIG } from 'src/app.config';
import { VerificationMethodsField } from 'src/components/form/fields/VerificationMethodsField';
import { SolidityFlattenedForm } from 'src/components/form/SolidityFlattenedForm';
import { SolidityMultipartForm } from 'src/components/form/SolidityMultipartForm';
import { SolidityStandardForm } from 'src/components/form/SolidityStandardForm';
import { NewContractSchema, newContractSchema } from 'src/components/form/verificationSchema';
import { VyperContractForm } from 'src/components/form/VyperContractForm';
import { VyperMultipartForm } from 'src/components/form/VyperMultipartForm';
import { PageTitle } from 'src/components/PageTitle';
import { UiHash } from 'src/components/UiAddress';
import { useFetch } from 'src/hooks/useFetch';
import useResponsive from 'src/hooks/useResponsive';
import { useWebsocket } from 'src/hooks/useWebsocket';
import FormProvider from 'src/providers/FormProvider';
import { ApiContractVerifacationConfig, VerificationMethodType } from 'src/types/MultidataApi';
import { getWsMessageForSend } from 'src/utils/getWsMessageForSend';

export const ContractVerification = () => {
  const address = useParams().address as string;
  const { fetchData, txStatus, data } = useFetch<ApiContractVerifacationConfig>(
    'failed to get contract verification config: ',
  );
  const isMobile = useResponsive('down', 'sm');
  const { fetchData: postData, txStatus: postDataStatus } = useFetch('failed to post data');
  const navigate = useNavigate();

  const verificationCallback = useCallback((event: any) => {
    const jsonResponse = JSON.parse(event.data);

    if (jsonResponse[3] !== 'verification_result') return;

    if (jsonResponse[4].status === 'success') {
      navigate(`/${CONFIG.routes.addressPage}/${address}?tab=contract`);
    }

    if (jsonResponse[4].errors?.contract_source_code) {
      methods.setError('details.contractCode', {
        message: jsonResponse[4].errors.contract_source_code[0],
      });
    }

    if (jsonResponse[4].errors?.files) {
      methods.setError('root.files', {
        message: jsonResponse[4].errors.files[0],
      });
    }
    setIsVerifying(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { sendMessage, isReady } = useWebsocket(verificationCallback);
  const [isVerifying, setIsVerifying] = useState(false);

  useEffect(() => {
    if (!isReady) return;

    sendMessage(getWsMessageForSend(`addresses:${address}`, 'phx_join'));
    sendMessage(getWsMessageForSend(`addresses:${address.toLowerCase()}`, 'phx_join'));

    return () => {
      sendMessage(getWsMessageForSend(`addresses:${address}`, 'phx_leave'));
      sendMessage(getWsMessageForSend(`addresses:${address.toLowerCase()}`, 'phx_leave'));
    };
  }, [sendMessage, isReady, address]);

  useEffect(() => {
    if (postDataStatus.error) {
      setIsVerifying(false);
    }
  }, [postDataStatus]);

  const defaultValues = {
    verificationMethod: '' as VerificationMethodType,
    details: {
      contractName: '',
      withNightlyBuilds: false,
      autodetectConstructorArgs: true,
      files: [] as File[],
      compilerVersion: '',
      evmVersion: '',
      withOptimization: true,
      isYulContract: false,
      contractCode: '',
      constructorArguments: '',
      withLibraries: false,
      optimizationRuns: 200,
      libraries: [] as { name: string; address: string }[],
    },
  };

  const methods = useForm<NewContractSchema>({
    resolver: yupResolver(newContractSchema),
    defaultValues,
  });

  const verificationMethod = methods.watch('verificationMethod');

  useEffect(() => {
    fetchData(api.multidata.getContractVerificationConfig());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getParams = () => {
    const {
      details: {
        compilerVersion,
        optimizationRuns,
        constructorArguments,
        isYulContract,
        withOptimization,
        contractCode,
        evmVersion,
        libraries,
        autodetectConstructorArgs,
        contractName,
        files,
      },
    } = methods.watch();

    const data: { [key: string]: any } = {
      compiler_version: compilerVersion,
      source_code: contractCode,
      is_optimization_enabled: withOptimization.toString(),
      is_yul_contract: isYulContract.toString(),
      optimization_runs: optimizationRuns.toString(),
      evm_version: evmVersion,
      autodetect_constructor_args: autodetectConstructorArgs.toString(),
      constructor_args: constructorArguments,
      contract_name: contractName,
      libraries: Object.fromEntries(libraries.map((item) => [item.name, item.address])),
    };

    let formData = new FormData();

    Object.keys(data).forEach((item) => {
      const value = typeof data[item] === 'string' ? data[item] : JSON.stringify(data[item]);
      formData.append(item, value);
    });

    if (files.length === 0) return data;

    files.forEach((v, i) => {
      formData.append(`files[${i}]`, v, v.name);
    });

    return formData;
  };

  const handleVerify = () => {
    setIsVerifying(true);

    postData(
      api.multidata.verifyAndPublishContract(
        address,
        methods.watch('verificationMethod') as VerificationMethodType,
        getParams(),
      ),
    );
  };

  if (txStatus.error) return <Typography>{txStatus.error}</Typography>;

  return (
    <>
      <PageTitle
        title={'New smart contract verification'}
        path={`/${CONFIG.routes.addressPage}/${address}?tab=contract`}
        tooltip="Back to contract"
        mb={2}
      />
      <UiHash
        hash={address}
        isContract
        withCopy
        mb={3}
        cutting={isMobile}
        cuttingOptions={[21, 4]}
      />
      <FormProvider methods={methods}>
        <Grid
          container
          columnSpacing={4}
          rowSpacing={2}
          sx={{
            code: {
              background: (theme) => theme.palette.background.neutral,
              paddingInline: '0.2em',
            },
            a: {
              color: (theme) => theme.palette.primary.main,
              textDecoration: 'none',
            },
          }}
        >
          <VerificationMethodsField disabled={isVerifying} />

          {verificationMethod === 'flattened-code' && (
            <SolidityFlattenedForm
              evmVersions={data?.solidity_evm_versions}
              compilers={data?.solidity_compiler_versions}
              disabled={isVerifying}
            />
          )}
          {verificationMethod === 'standard-input' && (
            <SolidityStandardForm
              compilers={data?.solidity_compiler_versions}
              disabled={isVerifying}
            />
          )}
          {verificationMethod === 'multi-part' && (
            <SolidityMultipartForm
              evmVersions={data?.solidity_evm_versions}
              compilers={data?.solidity_compiler_versions}
              disabled={isVerifying}
            />
          )}
          {verificationMethod === 'vyper-code' && (
            <VyperContractForm
              compilers={data?.vyper_compiler_versions}
              evmVersions={data?.vyper_evm_versions}
              disabled={isVerifying}
            />
          )}
          {verificationMethod === 'vyper-multi-part' && (
            <VyperMultipartForm
              evmVersions={data?.vyper_evm_versions}
              compilers={data?.vyper_compiler_versions}
              disabled={isVerifying}
            />
          )}

          {verificationMethod && (
            <Grid item mt={2} xs={12}>
              <Button
                startIcon={isVerifying ? <CircularProgress color="inherit" size={16} /> : null}
                disabled={isVerifying}
                variant="contained"
                onClick={methods.handleSubmit(handleVerify)}
              >
                Verify & publish
              </Button>
            </Grid>
          )}
        </Grid>
      </FormProvider>
    </>
  );
};
