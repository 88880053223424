import { Button, ButtonProps, Link, LinkProps, styled } from '@mui/material';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

const UiButtonLink = styled(Button)(() => ({
  '&:hover ': {
    textDecoration: 'underline',
    background: 'none',
  },
}));

export const ButtonLink = (props: ButtonProps) => {
  return <UiButtonLink variant="text" disableRipple {...props} />;
};

export const UiLink = (props: LinkProps & RouterLinkProps) => {
  return <Link component={RouterLink} {...props} />;
};
