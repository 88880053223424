import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';

import { ContractNameField } from 'src/components/form/fields/ContractNameField';
import { CompilerField } from 'src/components/form/fields/CompilerField';
import { ContractCodeField } from 'src/components/form/fields/ContractCodeField';
import { EVMversionField } from './fields/EVMversionField';

export const VyperContractForm: FC<{
  compilers?: string[];
  evmVersions?: string[];
  disabled?: boolean;
}> = ({ compilers, evmVersions, disabled }) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue('details.contractName', 'Vyper_contract');
    setValue('details.autodetectConstructorArgs', false);
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ color: 'text.disabled', mb: 1 }}>
          Contract verification via Vyper (contract)
        </Typography>
      </Grid>
      <ContractNameField text={'Must match the name specified in the code.'} disabled />
      <CompilerField compilers={compilers} disabled={disabled} />
      <EVMversionField versions={evmVersions} disabled={disabled} />
      <ContractCodeField disabled={disabled} />
    </>
  );
};
