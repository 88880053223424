import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { DecodedInputLogParameter } from 'src/types/MultidataApi';
import { UiHash } from '../UiAddress';
import useResponsive from 'src/hooks/useResponsive';
import { Label } from 'src/components/label/Label';

type ViewType = 'hex' | 'dec';

type Props = {
  topic: string;
  index: number;
  decodedData?: DecodedInputLogParameter[];
};

export const LogTopic = ({ topic, index, decodedData }: Props) => {
  const isMobile = useResponsive('down', 'md');

  const ableToDecode = index > 0 && decodedData && decodedData?.length > 0;

  const [dataView, setDataView] = useState<ViewType>(ableToDecode ? 'dec' : 'hex');

  const handleChangeView = (e: SelectChangeEvent) => {
    setDataView(e.target.value as ViewType);
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center" mt={index === 0 ? '0' : '0.75rem'}>
      <Label>{index}</Label>
      {index > 0 && decodedData && (
        <Select
          value={dataView}
          onChange={handleChangeView}
          sx={{
            maxWidth: '6.2rem',
            marginBottom: '0.5rem',
            '& .MuiInputBase-input': { padding: '4px 6px' },
          }}
        >
          <MenuItem value={'hex'}>Hex</MenuItem>
          <MenuItem value={'dec'}>Dec</MenuItem>
        </Select>
      )}
      {ableToDecode && dataView === 'dec' ? (
        decodedData[index - 1].type === 'address' ? (
          <UiHash hash={decodedData[index - 1].value} cutting={isMobile} variant="body1" />
        ) : (
          <Typography sx={{ overflowWrap: 'anywhere' }}>{decodedData[index - 1].value}</Typography>
        )
      ) : (
        <Typography sx={{ overflowWrap: 'anywhere' }}>{topic}</Typography>
      )}
    </Stack>
  );
};
