import { Grid, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { UiHash } from 'src/components/UiAddress';
import { MetricValues, MulV1FeedMetrics } from 'src/types/MultidataV1Api';
import { formatNumber } from 'src/utils/formatNumber';
import { numberWithDividers } from 'src/utils/number';
import { ValidatorDepShort } from './ValidatorDepShort';

export const ValidatorCard: FC<{
  validator: MetricValues;
  currentAsset: MulV1FeedMetrics;
  timePast: string;
  networkId?: string;
}> = ({ validator, currentAsset, timePast, networkId }) => {
  return (
    <Paper
      sx={{
        p: 2.5,
        display: 'grid',
        alignItems: 'top',
        alignContent: 'start',
        gridGap: 1,
        height: '100%',
        background: (theme) => theme.palette.background.neutral,
        position: 'relative',
        '&:hover': {
          background: (theme) => theme.palette.grey[300],
        },
      }}
    >
      <UiHash
        networkId={networkId}
        hash={validator.validator}
        variant="subtitle1"
        name={validator.validator_name}
        cutting
        textAlign="center"
        target="_blank"
        rel="noopener"
      />

      {
        <Typography variant="body2">
          Answer: {numberWithDividers(formatNumber.getDisplayFromQ112(validator.value, 0, 4))}{' '}
          {currentAsset.currency === 'PCT' ? '%' : currentAsset.currency || 'USD'}
        </Typography>
      }
      <Typography variant="body2" sx={{ mb: 2 }}>
        {timePast} ago
      </Typography>

      {validator.source_prices && (
        <>
          <Typography variant="subtitle1" pb={2}>
            Detailed prices
          </Typography>

          <Grid container spacing={1}>
            <ValidatorDepShort dep={validator.source_prices} />
          </Grid>
        </>
      )}
    </Paper>
  );
};
