import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { useEffect } from 'react';
import { api } from 'src/api';
import { PageTitle } from 'src/components/PageTitle';
import { UiHash } from 'src/components/UiAddress';
import TableHeadCustom from 'src/components/table/TableHeadCustom';
import { UiPagination } from 'src/components/ui/UiPagination';
import { useFetch } from 'src/hooks/useFetch';
import { usePaginationWithSearchParams } from 'src/hooks/usePaginationWithSearchParams';
import { ApiAccountsResp } from 'src/types/MultidataApi';
import { formatNumber } from 'src/utils/formatNumber';
import { numberWithDividers } from 'src/utils/number';

const TABLE_HEAD_LABELS = [
  { id: 'rank', label: 'Rank' },
  { id: 'address', label: 'Address' },
  // { id: 'publicTag', label: 'Public tag' },
  { id: 'balance', label: 'Balance CHUNK' },
  { id: 'txnCount', label: 'Txn count' },
];

export const Accounts = () => {
  const { fetchData, isLoading, txStatus, data: accounts } = useFetch<ApiAccountsResp>();

  const { handlePageChange, page, paginationCount, prevPages, queryArgs, items_count } =
    usePaginationWithSearchParams(accounts?.next_page_params || null);

  const contractDecimals = 8;

  useEffect(() => {
    fetchData(api.multidata.getAccounts(queryArgs ? `?${queryArgs}` : ' '));
  }, [queryArgs]);

  if (txStatus.error) return <p>{txStatus.error}</p>;

  return (
    <>
      <PageTitle title="Top accounts" path="/" tooltip="Back to main page" mb={4} />
      <UiPagination
        page={page}
        paginationCount={paginationCount}
        handlePageChange={handlePageChange}
        disabled={isLoading}
        emptyPrevPages={prevPages.length === 0}
      />
      <TableContainer
        sx={{
          '& th:first-of-type, td:first-of-type': {
            paddingLeft: 3,
          },
        }}
      >
        <Table stickyHeader sx={{ overflowX: 'scroll' }}>
          <TableHeadCustom headLabel={TABLE_HEAD_LABELS} />

          {isLoading ? (
            <TableBody>
              <tr>
                <td colSpan={TABLE_HEAD_LABELS.length}>
                  <p>Loading...</p>
                </td>
              </tr>
            </TableBody>
          ) : (
            <TableBody>
              {accounts?.items.map((account, index) => (
                <TableRow key={account.hash}>
                  <TableCell width={'1rem'}>
                    <Typography variant="body2" marginRight="2rem">
                      {items_count * (page - 1) + index + 1}
                    </Typography>
                  </TableCell>
                  <TableCell width={'1rem'}>
                    <UiHash hash={account.hash} isContract={account.is_contract} />
                  </TableCell>
                  {/* <TableCell>
                    <Typography variant="body2">
                      {account.public_tags.length > 0
                        ? account.public_tags.map((el) => el.display_name).join(' ')
                        : '-'}
                    </Typography>
                  </TableCell> */}
                  <TableCell width={'1rem'}>
                    <Typography variant="body2">
                      {numberWithDividers(
                        formatNumber.getDisplay(
                          account.coin_balance,
                          account.is_contract
                            ? contractDecimals
                            : parseInt(account.token?.decimals || '18'),
                          2,
                        ),
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{account.tx_count}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
