import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from 'src/api';
import { CONFIG } from 'src/app.config';
import { PageTitle } from 'src/components/PageTitle';
import { TokensTransfers } from 'src/components/TokensTransfers';
import { InternalTransactions } from 'src/components/transactionDetails/InternalTransactions';
import { TransactionDetails } from 'src/components/transactionDetails/TransactionDetails';
import { TransactionLogs } from 'src/components/transactionDetails/TransactionLogs';
import { TransactionRawTrace } from 'src/components/transactionDetails/TransactionRawTrace';
import { UiTabs } from 'src/components/ui/UiTabs';

export const Transaction = () => {
  const { hash } = useParams();
  const [tab, setTab] = useState('');

  const TABS = useMemo(
    () => [
      {
        value: 'index',
        label: 'Details',
        component: <TransactionDetails />,
      },
      {
        value: 'token_transfers',
        label: 'Token transfers',
        component: hash && (
          <TokensTransfers fetchTransfers={() => api.multidata.getTxTokenTransfers(hash)} />
        ),
      },
      {
        value: 'internal',
        label: 'Internal txns',
        component: <InternalTransactions />,
      },
      {
        value: 'logs',
        label: 'Logs',
        component: <TransactionLogs />,
      },
      {
        value: 'raw_trace',
        label: 'Raw trace',
        component: <TransactionRawTrace />,
      },
    ],
    [hash],
  );

  return (
    <>
      <PageTitle
        title="Transaction details"
        path={`/${CONFIG.routes.txsPage}`}
        tooltip="Back to transactions list"
        mb={2}
      />
      <UiTabs tab={tab} setTab={setTab} tabs={TABS} />
      {TABS.map(
        (el) =>
          el.value === tab && (
            <Box key={el.value} mt="2rem">
              {el.component}
            </Box>
          ),
      )}
    </>
  );
};
