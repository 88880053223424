import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UiTextFilled } from 'src/components/ui/UiTextFilled';
import { api } from 'src/api';
import { useFetch } from 'src/hooks/useFetch';

export const TransactionRawTrace = () => {
  const { hash } = useParams();

  const {
    fetchData,
    isLoading,
    txStatus,
    data: raw,
  } = useFetch<string>('failed to get transaction raw trace information: ');

  useEffect(() => {
    if (!hash) return;
    fetchData(api.multidata.getRawTrace(hash));
  }, [hash]);

  return (
    <div>
      {isLoading ? (
        <p>Loading...</p>
      ) : txStatus.error ? (
        <p>{txStatus.error}</p>
      ) : (
        <>{raw && <UiTextFilled maxRows={18} value={JSON.stringify(raw, null, 2)} />}</>
      )}
    </div>
  );
};
