import { Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from 'src/api';
import { useFetch } from 'src/hooks/useFetch';
import {
  MetricValues,
  MetricValuesSourcePricesDep,
  MulV1HistoryPrices,
} from 'src/types/MultidataV1Api';
import { PriceHistoryChart } from './asset/components/PriceHistoryChart';
import { ValidatorDep } from './asset/components/ValidatorDep';
import { ethers } from 'ethers';
import { PageTitle } from 'src/components/PageTitle';
import { UiGridContainer, UiGridItem } from 'src/components/ui/UiGrid';
import { UiInfoTooltip } from 'src/components/ui/UiInfoTooltip';
import { spaceBetweenCell } from 'src/utils/cssStyles';
import { useTimePast } from 'src/hooks/useTimePast';
import { UiHash } from 'src/components/UiAddress';
import { numberWithDividers } from 'src/utils/number';
import { formatNumber } from 'src/utils/formatNumber';
import moment from 'moment';
import useResponsive from 'src/hooks/useResponsive';
import { UiProgress } from 'src/components/ui/UiProgress';
import { Scrollbar } from 'src/components/scrollbar/Scrollbar';
import { formatTimeStampUtc } from 'src/utils/time';
import { DEFAULT_CHAIN_ID } from 'src/constants/chains';
import { ChainName } from 'src/types/Asset';
import { OracleType } from 'src/constants/oracle';
import { useFeed } from 'src/hooks/useFeed';
import { useNetworkAssets } from 'src/hooks/useNetworkAssets';

const ethProvider = new ethers.providers.JsonRpcProvider(
  'https://mainnet.infura.io/v3/db72eb2275564c62bfa71896870d8975',
);

export const Validator = () => {
  const {
    networkId = DEFAULT_CHAIN_ID,
    address,
    validator,
    oracleType,
  } = useParams<{
    address: string;
    validator: string;
    networkId: ChainName;
    oracleType: OracleType;
  }>();
  const { feedForView, dataFeedForView } = useFeed(networkId, oracleType);
  const { networkAssets, isLoading: isNetworkAssetsLoading } = useNetworkAssets(
    dataFeedForView?.network,
    dataFeedForView?.feedAddress,
  );
  const isMobile = useResponsive('down', 'sm');

  const [epochId, setEpochId] = useState<number>();

  const {
    fetchData: fetchHistoryPriceForValidator,
    txStatus: historyPriceStatus,
    data: historyPrice,
  } = useFetch<MulV1HistoryPrices[]>('failed to get stats information: ');

  const {
    fetchData,
    data: validators,
    isLoading: isLoadingValidators,
  } = useFetch<MetricValues[]>('failed to get validators by asset: ');
  const { fetchData: fetchDetailedPrices, data: detailedPrices } =
    useFetch<MetricValuesSourcePricesDep>('failed to get detailed prices: ');

  const currentValidator = useMemo(
    () => (validators ? validators?.find((v) => v.validator === validator) : null),
    [validator, validators],
  );

  const currentAsset = useMemo(
    () => (networkAssets ? networkAssets['all']?.find((asset) => asset.name === address) : null),
    [address, networkAssets],
  );

  const lastUpdated =
    feedForView && feedForView.updated ? formatTimeStampUtc(feedForView.updated) : '';
  const timePast = useTimePast(new Date(feedForView?.updated || '').getTime());

  useEffect(() => {
    if (!address || !feedForView || !validator) return;

    fetchHistoryPriceForValidator(
      api.multidataV1.getHistoryPricesForValidator(
        feedForView.name,
        feedForView.address,
        address,
        validator,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, feedForView, validator]);

  useEffect(() => {
    if (!feedForView || !address) return;

    fetchData(
      api.multidataV1.getChainFeedsMetricValues(feedForView.name, feedForView.address, address),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, feedForView]);

  const getDetailedPrices = async (epochId?: number) => {
    if (!feedForView || !address || !validator) return;

    await fetchDetailedPrices(
      api.multidataV1.getMetricValidatorDetailedValue(
        feedForView.name,
        feedForView.address,
        address,
        validator,
        epochId ? `?epoch_id=${epochId}` : '',
      ),
    );
    setEpochId(epochId);
  };

  if (isLoadingValidators) return <UiProgress text="Loading..." />;

  if (!currentValidator) return null;

  return (
    <>
      <PageTitle
        title={currentValidator.validator_name}
        path={`/oracles/${networkId}/${oracleType}/${address}`}
        tooltip="Back to metric"
        mt={5}
        mb={2}
      />
      <Grid container spacing={2}>
        <Grid item>
          <UiGridContainer mb={3} sx={{ display: { xs: 'grid' } }}>
            <UiGridItem>
              <Typography>Answer</Typography>
            </UiGridItem>
            <UiGridItem>
              <Typography variant="h6">
                {numberWithDividers(formatNumber.getDisplayFromQ112(currentValidator.value, 0, 4))}{' '}
                USD
              </Typography>
            </UiGridItem>

            <UiGridItem>
              <Typography>Asset Name</Typography>
            </UiGridItem>
            <UiGridItem>
              <Typography>{currentAsset?.symbol}</Typography>
            </UiGridItem>

            <UiGridItem>Validator address</UiGridItem>
            <UiGridItem>
              <UiHash
                variant="body1"
                networkId={feedForView?.name}
                hash={currentValidator.validator}
                cutting={isMobile}
                textAlign="center"
                target="_blank"
                rel="noopener"
              />
            </UiGridItem>

            <UiGridItem>Last update</UiGridItem>
            <UiGridItem>{lastUpdated}</UiGridItem>

            <UiGridItem sx={spaceBetweenCell}>
              <UiInfoTooltip title="Time since last answer was written on-chain" />
              <Typography>Time past</Typography>
            </UiGridItem>
            <UiGridItem>{timePast} ago</UiGridItem>
          </UiGridContainer>
        </Grid>
        <Grid item xs={12} lg={7}>
          {historyPrice ? (
            <PriceHistoryChart
              data={historyPrice}
              currency={currentAsset?.currency === 'PCT' ? '%' : currentAsset?.currency || 'USD'}
              sx={{ marginTop: 0, height: '260px' }}
              onClickHandler={getDetailedPrices}
            />
          ) : (
            historyPriceStatus.error && (
              <Typography variant="subtitle2" textAlign={{ desktop: 'center' }}>
                Problem with loading chart data
              </Typography>
            )
          )}
        </Grid>
      </Grid>

      {currentValidator.source_prices && (
        <Scrollbar>
          <Typography variant="h6" mt={2}>
            Detailed prices{' '}
            <Typography component="span">
              {epochId &&
                `on ${moment(epochId * 1000)
                  .utc()
                  .format('MMM DD h:mma')}`}
            </Typography>
          </Typography>
          <ValidatorDep
            dep={detailedPrices || currentValidator.source_prices}
            ethProvider={ethProvider}
          />
        </Scrollbar>
      )}
    </>
  );
};
