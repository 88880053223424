import { useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React, { FC } from 'react';
import { Iconify } from 'src/components/Iconify';

interface Props {
  onCopy: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const CopyIcon: FC<Props> = ({ onCopy }) => {
  const theme = useTheme();

  function handleCopy(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.preventDefault();
    e.nativeEvent.stopPropagation();
    onCopy(e);
  }

  return (
    <Iconify
      color={alpha(theme.palette.grey[500], 0.8)}
      sx={{ ml: 1, position: 'relative', zIndex: '1', cursor: 'pointer' }}
      icon="material-symbols:content-copy-outline"
      onClick={handleCopy}
    />
  );
};
