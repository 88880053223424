const navConfig = [
  {
    subheader: 'Home',
    path: 'https://chunk.limo',
    isExternal: true,
  },
  {
    subheader: 'Docs',
    path: 'https://docs.chunk.limo/',
    isExternal: true,
  },
  {
    subheader: 'SDK',
    path: 'https://github.com/chunklimo/sdk',
    isExternal: true,
  },
  // {
  //   subheader: 'Blockchain Explorer',
  //   items: [
  //     {
  //       title: 'Transactions',
  //       path: CONFIG.routes.txsPage,
  //       // icon: <Iconify icon="material-symbols:swap-horiz" />,
  //     },
  //     {
  //       title: 'Blocks',
  //       path: CONFIG.routes.blocksPage,
  //       // icon: <Iconify icon="clarity:block-line" />,
  //     },
  //     {
  //       title: 'Tokens',
  //       path: CONFIG.routes.tokensPage,
  //       // icon: <Iconify icon="iconoir:coins" />,
  //     },
  //     {
  //       title: 'Verified contracts',
  //       path: CONFIG.routes.verifiedContractsPage,
  //       // icon: <Iconify icon="material-symbols:check-circle-outline-rounded" />,
  //     },
  //   ],
  // },
  // {
  //   subheader: 'Supported Networks',
  //   path: '/oracles',
  //   items: SUPPORTED_NETWORKS.map((network) => {
  //     return {
  //       title: CHAINS.find((chain) => chain.id === network)?.name || '',
  //       path: `/oracles/${network}`,
  //     };
  //   }),
  // },
];

export default navConfig;
