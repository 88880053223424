import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import TableHeadCustom from 'src/components/table/TableHeadCustom';
import { useFetch } from 'src/hooks/useFetch';
import { ApiTokenTransfersResp } from 'src/types/MultidataApi';
import { UiHash } from 'src/components/UiAddress';
import { UiInfo } from 'src/components/ui/UiInfo';
import { Iconify } from 'src/components/Iconify';
import { formatNumber } from 'src/utils/formatNumber';
import { timeSince } from 'src/utils/time';
import { UiLink } from 'src/components/ui/UiLink';
import { CONFIG } from 'src/app.config';
import useResponsive from 'src/hooks/useResponsive';
import { numberWithDividers } from 'src/utils/number';
import { usePaginationWithSearchParams } from 'src/hooks/usePaginationWithSearchParams';
import { UiPagination } from 'src/components/ui/UiPagination';

export const TokensTransfers = ({
  fetchTransfers,
  isNft = false,
}: {
  fetchTransfers: (query?: string) => Promise<ApiTokenTransfersResp>;
  isNft?: boolean;
}) => {
  const theme = useTheme();
  const {
    fetchData,
    isLoading,
    txStatus,
    data: transfers,
  } = useFetch<ApiTokenTransfersResp>('failed to get token transfers: ');
  const isDownMD = useResponsive('down', 'md');
  const cuttingOptions: [number, number] = isDownMD ? [4, 4] : [10, 10];

  const { handlePageChange, page, paginationCount, prevPages, queryArgs } =
    usePaginationWithSearchParams(transfers?.next_page_params || null);

  const commonHeadLabels = useMemo(
    () => [
      { id: 'txn', label: 'Txn hash' },
      { id: 'method', label: 'Method', sx: { width: '10rem' } },
      { id: 'from', label: 'From' },
      { id: 'arrowIcon', label: '' },
      { id: 'to', label: 'To' },
    ],
    [],
  );

  const headLabels = useMemo(
    () =>
      isNft
        ? [
            ...commonHeadLabels,
            { id: 'tokenType', label: 'Type' },
            { id: 'tokenId', label: 'Token' },
          ]
        : [
            ...commonHeadLabels,
            { id: 'valueToken', label: 'Value' },
            { id: 'tokenLink', label: 'Token' },
          ],
    [commonHeadLabels, isNft],
  );

  useEffect(() => {
    fetchData(fetchTransfers(queryArgs ? `&${queryArgs}` : ''));
  }, [queryArgs]);

  if (isLoading) return <p>Loading...</p>;

  if (txStatus.error) return <p>{txStatus.error}</p>;

  return (
    <>
      <UiPagination
        page={page}
        paginationCount={paginationCount}
        handlePageChange={handlePageChange}
        disabled={isLoading}
        emptyPrevPages={prevPages.length === 0}
        sx={{ marginBottom: '1.5rem' }}
      />
      {transfers && transfers.items.length > 0 ? (
        <TableContainer>
          <Table stickyHeader sx={{ overflowX: 'scroll' }}>
            <TableHeadCustom headLabel={headLabels} />

            <TableBody>
              {transfers.items.map((transfer, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ width: '1px' }}>
                    <UiHash
                      hash={transfer.tx_hash}
                      cuttingOptions={cuttingOptions}
                      withCopy
                      cutting
                    />
                    {transfer.timestamp && (
                      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
                        {timeSince(Number(new Date(transfer.timestamp)))} ago
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ width: 'max-content' }}>
                      <UiInfo>{transfer.method}</UiInfo>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ width: '1px', whiteSpace: 'nowrap' }}>
                    <UiHash
                      hash={transfer.from.hash}
                      name={transfer.from.name}
                      cuttingOptions={cuttingOptions}
                      cutting
                      withCopy
                    />
                  </TableCell>
                  <TableCell sx={{ width: '1px' }}>
                    <Iconify
                      color={alpha(theme.palette.grey[500], 0.8)}
                      width={30}
                      icon={'material-symbols:arrow-circle-right-outline-rounded'}
                    />
                  </TableCell>
                  <TableCell sx={{ width: '1px', whiteSpace: 'nowrap' }}>
                    {
                      <UiHash
                        hash={transfer.to.hash}
                        name={transfer.to.name}
                        cuttingOptions={cuttingOptions}
                        cutting
                        withCopy
                      />
                    }
                  </TableCell>
                  {isNft ? (
                    <TableCell>
                      <Box sx={{ width: 'max-content' }}>
                        <UiInfo>{transfer.token.type}</UiInfo>
                      </Box>
                    </TableCell>
                  ) : (
                    <TableCell sx={{ width: '1px' }}>
                      <Typography>
                        {transfer.total.value
                          ? numberWithDividers(
                              formatNumber.getDisplay(
                                transfer.total.value,
                                parseInt(transfer.total.decimals || '18'),
                                4,
                                false,
                              ),
                            )
                          : '0'}
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Typography>
                      <UiLink
                        to={
                          isNft
                            ? `/${CONFIG.routes.tokenPage}/${transfer.token.address}/instance/${transfer.total.token_id}`
                            : `/${CONFIG.routes.tokenPage}/${transfer.token.address}`
                        }
                      >
                        {transfer.token.name}
                        {isNft && `#${transfer.total.token_id}`}{' '}
                        {transfer.token.symbol && `(${transfer.token.symbol})`}
                      </UiLink>
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>There are no token transfers for this transaction.</Typography>
      )}
    </>
  );
};
