import { Box, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useNewTxCount } from 'src/hooks/useNewTxCount';
import { ButtonLink } from 'src/components/ui/UiLink';

const cssReloadBtn = {
  display: 'inline-block',
  verticalAlign: 'unset',
  fontSize: '1rem',
  fontWeight: 'normal',
  padding: 0,
  margin: 0,
  textTransform: 'inherit',
};

type Props = {
  forTable?: boolean;
  onCounterClick?: () => void;
  address?: string;
};

export const NewTxsNotification = ({ forTable = false, onCounterClick, address }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [newTxCount, wsReady] = useNewTxCount(address);

  const getView = (forTable: boolean) => {
    return forTable ? (
      <TableRow sx={{ background: theme.palette.background.neutral }}>
        <TableCell colSpan={10} sx={{ fontSize: '1rem', color: theme.palette.text.secondary }}>
          {wsReady ? (
            <>
              <ButtonLink
                onClick={onCounterClick}
                sx={{ ...cssReloadBtn, color: theme.palette.text.secondary }}
              >
                {newTxCount} more transactions
              </ButtonLink>{' '}
              have come in
            </>
          ) : (
            <>
              Connection is lost. Please{' '}
              <ButtonLink
                onClick={() => navigate(0)}
                sx={{ ...cssReloadBtn, color: theme.palette.text.secondary }}
              >
                reload page.
              </ButtonLink>
            </>
          )}
        </TableCell>
      </TableRow>
    ) : (
      <Box sx={{ padding: '1rem 1.5rem 0 0' }}>
        <Typography color={theme.palette.text.secondary}>
          {wsReady ? (
            <>
              <ButtonLink
                onClick={onCounterClick}
                sx={{ ...cssReloadBtn, color: theme.palette.text.secondary }}
              >
                {newTxCount} more transactions
              </ButtonLink>{' '}
              have come in
            </>
          ) : (
            <>
              Connection is lost. Please{' '}
              <ButtonLink
                onClick={() => navigate(0)}
                sx={{ ...cssReloadBtn, color: theme.palette.text.secondary }}
              >
                reload page.
              </ButtonLink>
            </>
          )}
        </Typography>
      </Box>
    );
  };

  return newTxCount > 0 ? getView(forTable) : null;
};
