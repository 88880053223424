import { Paper, SxProps, useTheme } from '@mui/material';
import { FC } from 'react';
import { Scrollbar } from 'src/components/scrollbar/Scrollbar';

export const UiCodeBlock: FC<{ code: string; sx?: SxProps }> = ({ code, sx }) => {
  const theme = useTheme();
  return (
    <Paper
      sx={{
        background: theme.palette.grey[200],
        overflow: 'auto',
        height: 250,
        wordBreak: 'break-all',
        whiteSpace: 'pre-wrap',
        ...sx,
      }}
    >
      <Scrollbar
        sx={{
          px: 2.5,
        }}
      >
        {code}
      </Scrollbar>
    </Paper>
  );
};
