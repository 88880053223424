import { Box, TextField, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { DecodedInputParameter } from 'src/types/MultidataApi';

type Props = {
  inputs: DecodedInputParameter[];
  lastElementIndex: number;
  register: UseFormRegister<FieldValues>;
};

export const ContractInputs: FC<Props> = ({ inputs, lastElementIndex, register }) => {
  const theme = useTheme();

  return (
    <Box>
      {inputs.map((input, i) => {
        const { name, type } = input;
        const isLastElement = i === lastElementIndex;
        const inputName = name ? name : type;

        return (
          <Typography
            key={i}
            component="label"
            sx={{ display: 'block', marginBottom: isLastElement ? 0 : '1rem' }}
          >
            <Typography mb="0.2rem">
              {inputName}{' '}
              <Typography
                component="i"
                sx={{ fontSize: '0.825rem', color: `${theme.palette.grey[600]}` }}
              >
                {' '}
                ({type})
              </Typography>
            </Typography>
            <TextField
              {...register(name ? name : `${type}${i}`)}
              placeholder={`${inputName} (${type})`}
              sx={{
                width: '100%',
                '& input': { fontSize: '0.875rem', padding: '0.5rem 0.375rem' },
              }}
            />
          </Typography>
        );
      })}
    </Box>
  );
};
