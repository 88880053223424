import {
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { api } from 'src/api';
import { CONFIG } from 'src/app.config';
import { PageTitle } from 'src/components/PageTitle';
import TableHeadCustom from 'src/components/table/TableHeadCustom';
import { UiLink } from 'src/components/ui/UiLink';
import { useFetch } from 'src/hooks/useFetch';
import { ApiSearch, NextPageSearchParams, SearchResult } from 'src/types/MultidataApi';

const TABLE_HEAD_LABELS = [
  { id: 'searchResult', label: 'Search result' },
  { id: 'address', label: 'Address' },
  { id: 'category', label: 'Category' },
];

export const SearchResults = () => {
  const location = useLocation();

  const searchState: {
    items?: SearchResult[];
    nextPageParams: NextPageSearchParams | null;
  } = location.state;

  const [searchResult, setSearchResult] = useState<{
    items: SearchResult[];
    nextPageParams: NextPageSearchParams | null;
  } | null>(null);

  const items_count = 50;

  const searchAmount = useMemo(
    () => (searchResult?.nextPageParams ? `${items_count}+` : searchResult?.items.length || 0),
    [searchResult],
  );

  const { fetchData, isLoading, txStatus, data } = useFetch<ApiSearch>('failed to search: ');

  const [query, setQuery] = useSearchParams();
  const queryParams = useMemo(() => {
    const q = query.get('q') || '';
    const queryItemsCount = query.get('items_count');
    const queryPageNumber = query.get('page');
    const queryHolderCount = query.get('holder_count');
    const address_hash = query.get('address_hash') || '';
    const name = query.get('name') || '';
    const inserted_at = query.get('inserted_at') || '';
    const item_type = query.get('item_type') || '';
    const holder_count = queryHolderCount ? parseInt(queryHolderCount) : 0;
    const items_count = queryItemsCount ? parseInt(queryItemsCount) : 0;
    const page_number = queryPageNumber ? parseInt(queryPageNumber) : 1;
    const block_hash = query.get('block_hash') || '';
    const tx_hash = query.get('tx_hash') || '';
    return {
      q,
      items_count,
      page_number,
      holder_count,
      address_hash,
      name,
      inserted_at,
      item_type,
      block_hash,
      tx_hash,
    };
  }, [query]);

  const [page, setPage] = useState(1);
  const [paginationCount, setPaginationCount] = useState(0);
  const [prevPages, setPrevPages] = useState<NextPageSearchParams[]>([]);

  useEffect(() => {
    if (searchState) {
      setSearchResult({
        items: searchState.items || [],
        nextPageParams: searchState.nextPageParams,
      });
      return;
    }

    const { page_number } = queryParams;
    setPage(page_number);

    fetchSelectedSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, searchState]);

  useEffect(() => {
    if (!searchResult?.nextPageParams) return;

    setPaginationCount(
      Math.round((searchResult.nextPageParams.items_count + items_count) / items_count),
    );
  }, [searchResult]);

  useEffect(() => {
    if (!data) return;
    setSearchResult({
      items: data.items,
      nextPageParams: data.next_page_params,
    });
  }, [data]);

  async function fetchSelectedSearch() {
    let queryString = '?';
    (Object.keys(queryParams) as Array<keyof typeof queryParams>).forEach((param) => {
      queryString += queryParams[param] ? `&${param}=${queryParams[param]}` : '';
    });

    fetchData(api.multidata.search(queryString));
  }

  function handlePageChange(pageNumber: number) {
    const { q } = queryParams;
    if (pageNumber === 1) {
      setQuery({
        q,
      });
      setPrevPages([]);
      return;
    }
    if (pageNumber < page) {
      if (prevPages.length === 0) return;
      const prevPage = prevPages[prevPages.length - 1];
      setQuery({
        holder_count: String(prevPage.holder_count),
        items_count: String(prevPage.items_count),
        page: String(pageNumber),
      });
      setPrevPages((prev) => {
        prev.pop();
        return prev;
      });
      return;
    }

    if (!searchResult?.nextPageParams) return;

    setPrevPages((prev) => [...prev, queryParams]);
    setQuery({
      address_hash: String(searchResult.nextPageParams.address_hash),
      holder_count: String(searchResult.nextPageParams.holder_count),
      inserted_at: searchResult.nextPageParams.inserted_at,
      items_count: String(searchResult.nextPageParams.items_count),
      name: searchResult.nextPageParams.name,
      item_type: searchResult.nextPageParams.item_type,
      tx_hash: String(searchResult.nextPageParams.tx_hash),
      block_hash: String(searchResult.nextPageParams.block_hash),
      page: String(pageNumber),
    });
  }

  if (!searchState && txStatus.error) return <p>{txStatus.error}</p>;

  return (
    <>
      <PageTitle title="Search results" mb={2} />
      <Typography>
        Found <b>{searchAmount}</b> matching result for “<b>{queryParams.q}</b>”
      </Typography>
      {!!searchAmount && (
        <Stack direction="row" alignItems="flex-end">
          <Pagination
            page={page}
            count={paginationCount}
            variant="soft"
            shape="rounded"
            onChange={(_, page) => handlePageChange(page)}
            style={{ marginLeft: 'auto' }}
            disabled={isLoading}
            siblingCount={0}
            boundaryCount={0}
            hidePrevButton={prevPages.length === 0}
            showFirstButton
          />
        </Stack>
      )}
      {searchResult?.items && searchResult.items?.length > 0 && (
        <TableContainer
          sx={{
            mt: 3,
            '& th:first-of-type, td:first-of-type': {
              paddingLeft: 3,
            },
          }}
        >
          <Table stickyHeader sx={{ overflowX: 'scroll' }}>
            <TableHeadCustom headLabel={TABLE_HEAD_LABELS} />
            <TableBody>
              {searchResult.items.map((el, index) =>
                el.type === 'token' ? (
                  <SearchRow
                    key={index}
                    link={`/${CONFIG.routes.tokenPage}/${el.address}`}
                    name={`${el.name} (${el.symbol})`}
                    address={el.address}
                    category="Token"
                  />
                ) : el.type === 'block' ? (
                  <SearchRow
                    key={index}
                    link={`/${CONFIG.routes.blockPage}/${el.block_number}`}
                    name={el.block_number.toString()}
                    address={el.block_hash}
                    category="Block"
                  />
                ) : el.type === 'transaction' ? (
                  <SearchRow
                    key={index}
                    link={`/${CONFIG.routes.txPage}/${el.tx_hash}`}
                    name={el.tx_hash}
                    address={''}
                    category="Transaction"
                  />
                ) : (
                  <SearchRow
                    key={index}
                    link={`/${CONFIG.routes.addressPage}/${el.address}`}
                    name={el.address}
                    address={el.name || ''}
                    category="Address"
                  />
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

type RowProps = {
  link: string;
  name: string;
  address: string;
  category: string;
};

const SearchRow = ({ link, name, address, category }: RowProps) => (
  <TableRow>
    <TableCell sx={{ width: '1px', verticalAlign: 'top' }}>
      <UiLink to={link}>{name}</UiLink>
    </TableCell>
    <TableCell sx={{ width: '1px', verticalAlign: 'top' }}>{address}</TableCell>
    <TableCell sx={{ verticalAlign: 'top' }}>{category}</TableCell>
  </TableRow>
);
