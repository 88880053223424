import { Box, BoxProps, IconButton, Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Iconify } from 'src/components/Iconify';

type Props = {
  title: string;
  tooltip?: string;
  path?: string;
};

const container = {
  display: 'flex',
  alignItems: 'center',
};

export const PageTitle = ({ title, tooltip, path, ...props }: Props & BoxProps) => {
  return (
    <Box sx={container} {...props}>
      {path && (
        <Tooltip title={tooltip}>
          <Link to={path}>
            <IconButton>
              <Iconify
                sx={{ transform: 'rotate(-180deg)' }}
                icon="material-symbols:arrow-right-alt-rounded"
              />
            </IconButton>
          </Link>
        </Tooltip>
      )}

      <Typography variant="subtitle1">{title}</Typography>
    </Box>
  );
};
