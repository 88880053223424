import { Grid, Typography } from '@mui/material';
import RHFTextField from '../RHFTextField';

export const ConstructorArgumentsField = ({ withText }: { withText?: boolean }) => {
  return (
    <>
      <Grid item xs={12} md={8}>
        <RHFTextField
          name="details.constructorArguments"
          label="ABI-encoded Constructor Arguments*"
          multiline
          rows={5}
          sx={{
            '& textarea': {
              resize: 'vertical',
            },
          }}
        />
      </Grid>
      {withText && (
        <Grid item xs={12} md={4}>
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
            }}
          >
            Add arguments in{' '}
            <a
              href={'https://docs.soliditylang.org/en/develop/abi-spec.html'}
              target="_blank"
              rel="noopener noreferrer"
            >
              ABI hex encoded form
            </a>{' '}
            if required by the contract. Constructor arguments are written right to left, and will
            be found at the end of the input created bytecode. They may also be{' '}
            <a href={'https://abi.hashex.org/'} target="_blank" rel="noopener noreferrer">
              parsed here
            </a>
            .
          </Typography>
        </Grid>
      )}
    </>
  );
};
