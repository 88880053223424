import { useContext, useEffect, useMemo } from 'react';
import { AssetsCtx } from 'src/providers/AssetsProvider';
import { useFetch } from './useFetch';
import { api } from 'src/api';
import { MulV1LinkedTx } from 'src/types/MultidataV1Api';

export const useLinkedTx = (writeChainId: string | undefined, hash: string | undefined) => {
  const { feeds } = useContext(AssetsCtx);

  const {
    fetchData: fetchLinkedTx,
    data: linkedTx,
    isLoading: isLinkedTxLoading,
  } = useFetch<MulV1LinkedTx>('failed to get linked tx: ');

  const currentFeed = useMemo(
    () => (writeChainId ? feeds.find((feed) => feed.name === writeChainId) : null),
    [feeds, writeChainId],
  );

  useEffect(() => {
    if (!writeChainId || !currentFeed || !hash) return;

    fetchLinkedTx(api.multidataV1.getChainFeedsLinkedTx(writeChainId, currentFeed.address, hash));
  }, [writeChainId, currentFeed, hash]);

  return linkedTx;
};
