import { BoxProps, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React, { forwardRef } from 'react';
import { Iconify } from 'src/components/Iconify';

export const IconContract = () => {
  const theme = useTheme();

  return (
    <Iconify
      sx={{ mr: 0.5 }}
      color={alpha(theme.palette.grey[500], 0.8)}
      icon="mdi:file-document-outline"
    />
  );
};

export const IconCode = forwardRef<SVGElement, BoxProps>(({ sx, ...others }, ref) => {
  const theme = useTheme();

  return (
    <Iconify
      mr={0.5}
      color={alpha(theme.palette.grey[500], 0.8)}
      icon="ph:code-bold"
      {...others}
      ref={ref}
    />
  );
});

export const IconValidator = () => {
  const theme = useTheme();

  return (
    <Iconify
      sx={{ mr: 0.5 }}
      color={alpha(theme.palette.grey[500], 0.8)}
      icon="tabler:square-letter-v"
    />
  );
};
