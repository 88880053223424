import ace from 'ace-builds';
import { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material';

import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/ext-language_tools';
require('ace-mode-solidity/build/remix-ide/mode-solidity');

type Props = {
  value: string;
};

const AceEditor = ({ value }: Props) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  useEffect(() => {
    if (!editorRef.current) return;

    const editor = ace.edit(editorRef.current);
    editor.setTheme('ace/theme/chrome');
    editor.session.setMode('ace/mode/solidity');
    editor.setOption('showPrintMargin', false);

    const session = editor.getSession();
    session.setValue(value);
    editor.clearSelection();

    editor.setReadOnly(true);

    return () => {
      editor.destroy();
    };
  }, [value]);

  return (
    <div
      ref={editorRef}
      style={{
        width: '100%',
        height: '100%',
        minHeight: '300px',
        background: theme.palette.grey[200],
      }}
    />
  );
};

export default AceEditor;
