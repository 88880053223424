import { FC, useCallback, useMemo } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Box, Paper, Stack, Theme, Typography, useTheme } from '@mui/material';
import { ChainName, ChainProps } from 'src/types/Asset';
import { Scrollbar } from './scrollbar/Scrollbar';
import { SCROLL_BAR_MAX_SIZE } from 'src/constants/ui';
import { OracleType } from 'src/constants/oracle';

const styleOverrides = {
  container: {
    overflowX: 'scroll',
    scrollbarHeight: 0,
    scrollbarWidth: 0,
    paddingBottom: '1rem',
    '&::-webkit-scrollbar': {
      height: 0,
      width: 0,
    },
  },
  paper: ({
    selected,
    disabled,
    theme,
  }: {
    selected: boolean;
    disabled?: boolean;
    theme: Theme;
  }) => ({
    background: selected ? theme.palette.grey[300] : theme.palette.background.neutral,
    minWidth: '8rem',
    height: '100%',
    display: 'grid',
    gridGap: '0.5rem',
    justifyItems: 'center',
    alignItems: 'center',
    p: 2,
    color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
    '& svg': {
      width: 'auto',
      maxWidth: '100%',
      height: '3rem',
      fill: 'transparent',
    },
    '&:hover': {
      background: theme.palette.grey[300],
      border: 'none',
    },
  }),
};

type Props = {
  networks: ChainProps[];
  selectedId?: ChainName;
  onSelect?: (id: ChainName) => void;
  networksForAsset?: string[];
  // networksForAsset?: {
  //   name: string;
  //   oracleType: OracleType;
  // }[];
  disabled?: boolean;
};

export const NetworkIconsList: FC<Props> = ({
  networks,
  selectedId,
  onSelect,
  networksForAsset,
  disabled,
}) => {
  const theme = useTheme();
  const { pathname } = useLocation();

  const getLink = (el: string) => (selectedId ? pathname.replace(`/${selectedId}`, `/${el}`) : '');

  // if required networksForAsset

  // const { oracleType } = useParams<{
  //   oracleType: OracleType;
  // }>();

  // const getLink = useCallback(
  //   (el: string) =>
  //     selectedId
  //       ? pathname.replace(
  //           `/${selectedId}/${oracleType}`,
  //           `/${el}/${networksForAsset?.find((feed) => feed.name === el)?.oracleType}`,
  //         )
  //       : '',
  //   [networksForAsset, pathname, selectedId],
  // );

  // const networkNamesForAsset = useMemo(
  //   () => networksForAsset?.map((feed) => feed.name),
  //   [networksForAsset],
  // );

  const onClickHandler = (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>, el: ChainName) => {
    if (!onSelect) {
      return;
    }

    if (disabled) {
      evt.preventDefault();
    } else {
      onSelect(el);
    }
  };

  return (
    <Scrollbar scrollbarMaxSize={SCROLL_BAR_MAX_SIZE}>
      <Stack
        direction="row"
        spacing={2}
        pb="1rem"
        sx={{
          '& > a': {
            cursor: disabled ? 'default' : 'pointer',
          },
          '& > a > div': {
            opacity: disabled ? 0.5 : 1,
          },
          '& > a:hover > div': {
            background: disabled ? '#F4F6F8' : '',
          },
        }}
      >
        {networks.map((el) => (
          <Link
            key={el.id}
            to={getLink(el.id)}
            style={{
              textDecoration: 'none',
              pointerEvents:
                !networksForAsset || networksForAsset.includes(el.id) ? 'auto' : 'none',
            }}
            onClick={(evt) => {
              onClickHandler(evt, el.id);
            }}
          >
            <Paper
              sx={styleOverrides.paper({
                selected: el.id === selectedId,
                theme,
                disabled: !!networksForAsset && !networksForAsset.includes(el.id),
              })}
            >
              {el?.id === 'chunk' ? <Box sx={{ maxWidth: '80px' }}>{el?.icon}</Box> : el?.icon}
              {el?.id !== 'optimism' && el?.id !== 'chunk' && (
                <Typography variant="caption">{el?.name}</Typography>
              )}
            </Paper>
          </Link>
        ))}
      </Stack>
    </Scrollbar>
  );
};
