import { useCallback, useEffect, useState } from 'react';
import { WS_GET_TRANSACTIONS, WS_LEAVE_TRANSACTIONS } from 'src/constants/websocket';
import { useWebsocket } from 'src/hooks/useWebsocket';
import { getWsMessageForSend } from 'src/utils/getWsMessageForSend';

export const useNewTxCount = (address?: string) => {
  const [newTxCount, setNewTxCount] = useState(0);

  const newTxCallback = useCallback((event: any) => {
    const jsonResponse = JSON.parse(event.data);
    if (jsonResponse[3] === 'transaction') {
      setNewTxCount((count) => count + 1);
    }
  }, []);

  const { sendMessage, isReady } = useWebsocket(newTxCallback);

  useEffect(() => {
    if (!isReady) return;

    sendMessage(
      address ? getWsMessageForSend(`addresses:${address}`, 'phx_join') : WS_GET_TRANSACTIONS,
    );

    return () =>
      sendMessage(
        address ? getWsMessageForSend(`addresses:${address}`, 'phx_leave') : WS_LEAVE_TRANSACTIONS,
      );
  }, [sendMessage, isReady, address]);

  return [newTxCount, isReady] as const;
};
