import { useEffect, useState } from 'react';
import { MulV1Feed } from 'src/types/MultidataV1Api';
import { useNetworkFeeds } from './useNetworkFeeds';
import { ChainName } from 'src/types/Asset';
import { OracleType } from 'src/constants/oracle';

export const useCurrentFeedByOracleType = (
  currentNetwork: ChainName | undefined,
  oracleType: OracleType | undefined,
) => {
  const [currentFeed, setCurrentFeed] = useState<MulV1Feed | undefined>();

  const { networkFeeds } = useNetworkFeeds(currentNetwork);

  useEffect(() => {
    if (!networkFeeds || !oracleType) return;
    const currentFeed = networkFeeds.find((feed) => feed.type === oracleType);
    setCurrentFeed(currentFeed);
  }, [networkFeeds, oracleType]);

  return { currentFeed };
};
