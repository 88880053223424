export const CONFIG = {
  service: {
    url: 'https://explorer.chain.chunk.limo',
    api: 'https://explorer.chain.chunk.limo/api/v2',
    apiV1: 'https://api.chunk.limo/v1',
    wsUrl: 'wss://explorer.chain.chunk.limo/socket/v2/websocket?vsn=2.0.0',
  },
  routes: {
    blockPage: 'block',
    blocksPage: 'blocks',
    addressPage: 'address',
    txPage: 'tx',
    txsPage: 'txs',
    tokenPage: 'token',
    tokensPage: 'tokens',
    oraclesPage: 'oracles',
    searchPage: 'search-results',
    verifiedContractsPage: 'verified-contracts',
    accounts: 'accounts',
  },
  chains: {
    chunk: {
      networkId: 112211,
      name: 'Chunk',
      token: {
        name: 'Chunk',
        symbol: 'CHUNK',
        decimals: 18,
      },
      rpcUrls: ['https://rpc.chain.chunk.limo/'],
      blockExplorerUrls: ['https://explorer.chunk.limo/'],
    },
  },
} as const;
