import { IconButton, styled } from '@mui/material';

export const UiIconTabButton = styled(IconButton)(({ theme }) => ({
  width: '1.5rem',
  height: '1.5rem',
  padding: '0.375rem',
  background: theme.palette.grey[200],
  borderRadius: '0.25rem',
  '&:hover': { color: theme.palette.primary.light },
}));
