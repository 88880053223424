import { Typography } from '@mui/material';
import { UiHash } from '../UiAddress';

export const ProxyContractMessage = ({
  address,
  name,
}: {
  address: string;
  name: string | null;
}) => {
  return (
    <Typography mb="2rem" sx={{ display: 'flex', flexWrap: 'wrap' }}>
      Proxy contract for:{' '}
      <UiHash
        hash={address}
        name={name}
        withCopy
        cutting
        cuttingOptions={[8, 8]}
        sx={{ marginLeft: '0.4rem' }}
      />
    </Typography>
  );
};
