import React, { useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';
import { Box, Button } from '@mui/material';
import { UiHash } from './UiAddress';
import { CONFIG } from 'src/app.config';

export type WalletData = {
  signer: ethers.Signer;
};

type WalletConnectProps = {
  onConnect: (walletData: WalletData) => void;
  onChainChange: (chainId: number | null) => void;
};

export const WalletConnect: React.FC<WalletConnectProps> = ({ onConnect, onChainChange }) => {
  const [address, setAddress] = useState<string>('');

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        await checkConnectedWallet();
      } catch (error) {
        console.error('Error connecting wallet:', error);
      }
    } else {
      console.error('No Ethereum provider found');
    }
  };

  const checkConnectedWallet = useCallback(async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await provider.getNetwork();
        const currentAddress = await provider.getSigner().getAddress();
        setAddress(currentAddress);
        onConnect({ signer: provider.getSigner() });
        onChainChange(network.chainId);

        if (network.chainId !== CONFIG.chains.chunk.networkId) {
          switchNetwork();
        }
      } catch (error) {
        console.error('Error connecting wallet:', error);
      }
    } else {
      console.error('No Ethereum provider found');
    }
  }, []);

  const switchNetwork = useCallback(async () => {
    if (window.ethereum) {
      const { chains } = CONFIG;
      const { chunk } = chains;
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: ethers.utils.hexValue(chunk.networkId),
              chainName: chunk.name,
              nativeCurrency: {
                name: chunk.token.name,
                symbol: chunk.token.symbol,
                decimals: chunk.token.decimals,
              },
              rpcUrls: chunk.rpcUrls,
              blockExplorerUrls: chunk.blockExplorerUrls,
            },
          ],
        });
      } catch (error) {
        console.error('Error switching network:', error);
      }
    } else {
      console.error('No Ethereum provider found');
    }
  }, []);

  const handleSwitchAccounts = useCallback((accounts: string[]) => {
    if (accounts.length > 0) {
      setAddress(accounts[0]);
    } else {
      setAddress('');
      onChainChange(null);
    }
  }, []);

  const handleChainChange = useCallback((chainId: string) => {
    onChainChange(parseInt(chainId));
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.addListener('accountsChanged', handleSwitchAccounts);
      window.ethereum.addListener('chainChanged', handleChainChange);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', handleSwitchAccounts);
        window.ethereum.removeListener('chainChanged', handleChainChange);
      }
    };
  }, []);

  return (
    <div>
      {address ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              width: '0.825rem',
              height: '0.825rem',
              marginRight: '0.2rem',
              borderRadius: '50%',
              background: '#007B55',
            }}
          />
          Connected:{' '}
          <UiHash
            hash={address}
            sx={{ marginLeft: '0.2rem', span: { fontSize: '1rem' } }}
            cutting
          />
        </Box>
      ) : (
        <Button variant="contained" type="submit" onClick={connectWallet}>
          Connect Wallet
        </Button>
      )}
    </div>
  );
};
