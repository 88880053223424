import { ClickAwayListener } from '@mui/base';
import { Box, Paper, useTheme } from '@mui/material';
import { ReactNode, useState } from 'react';
import { Iconify } from 'src/components/Iconify';

export default function UiDropDown({ label, body }: { label: ReactNode; body: ReactNode }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ position: 'relative' }}>
        <Paper
          onClick={handleClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '0.5rem',
            minWidth: '6rem',
            color: open ? theme.palette.text.primary : theme.palette.grey[600],
            fontWeight: 400,
            background: theme.palette.background.neutral,
            borderRadius: '8px',
            cursor: 'pointer',
            opacity: '0.8',

            '&:hover': {
              color: theme.palette.text.primary,
            },
          }}
        >
          {label}
          <Iconify
            icon={'material-symbols:expand-more'}
            sx={{ marginLeft: '0.2rem', transform: open ? 'rotate(180deg)' : '' }}
          />
        </Paper>
        {open && (
          <Paper
            sx={{
              position: 'absolute',
              top: 45,
              right: 0,
              left: 0,
              zIndex: 1000,
              minWidth: 'max-content',
              bgColor: 'background.paper',
              borderRight: 'none',
              boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
            }}
          >
            {body}
          </Paper>
        )}
      </Box>
    </ClickAwayListener>
  );
}
