import { Grid } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { NetworkIconsList } from 'src/components/NetworkIconsList';
import { Prices } from 'src/components/Prices';
import { Validators } from 'src/components/Validators';
import { UiProgress } from 'src/components/ui/UiProgress';
import { CHAINS, DEFAULT_CHAIN_ID } from 'src/constants/chains';
import { OracleType } from 'src/constants/oracle';
import { useFeed } from 'src/hooks/useFeed';
import { useNetworkAssets } from 'src/hooks/useNetworkAssets';
import { useNetworkValidators } from 'src/hooks/useNetworkValidators';
import { AssetsCtx } from 'src/providers/AssetsProvider';
import { ChainName } from 'src/types/Asset';
import { OracleTypeTabs } from 'src/components/OracleTypeTabs';
import { RemoteDataStatus } from 'src/utils/remoteData';

export const Home = () => {
  const { feeds, isLoadingFeeds, feedsTxStatus } = useContext(AssetsCtx);
  const [currentNetwork, setCurrentNetwork] = useState<ChainName>(DEFAULT_CHAIN_ID);
  const [tab, setTab] = useState<OracleType | undefined>();
  const { feedForView, dataFeedForView } = useFeed(currentNetwork, tab);
  const { networkValidators, isLoadingValidators } = useNetworkValidators(dataFeedForView?.network);

  const { networkAssets, isLoading: isNetworkAssetsLoading } = useNetworkAssets(
    dataFeedForView?.network,
    dataFeedForView?.feedAddress,
  );

  const networks = useMemo(
    () =>
      CHAINS.filter((el) => feeds.map((feed) => feed.name).includes(el.id)).sort((x, y) =>
        x.id === 'chunk' ? -1 : y.id === 'chunk' ? 1 : 0,
      ),
    [feeds],
  );

  if (isLoadingFeeds || feedsTxStatus?.status === RemoteDataStatus.notAsked)
    return <UiProgress text="Loading feeds..." />;

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item container xs={12} alignItems="flex-end" spacing={2} mt={2}>
        <Grid item xs={12}>
          <NetworkIconsList
            networks={networks}
            selectedId={currentNetwork}
            onSelect={setCurrentNetwork}
            disabled={isNetworkAssetsLoading || isLoadingValidators}
          />
          <OracleTypeTabs
            tab={tab}
            setTab={setTab}
            currentNetwork={currentNetwork}
            isDisabled={isNetworkAssetsLoading}
          />
          <Prices
            oracleType={tab}
            networkId={currentNetwork}
            networkAssets={networkAssets}
            isLoading={isNetworkAssetsLoading}
          />
          <Validators
            currentFeed={feedForView}
            networkValidators={networkValidators}
            isLoading={isLoadingValidators}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
