import { getWsMessageForSend } from 'src/utils/getWsMessageForSend';

export const TIME_TO_RECONNECT = 10000;
export const TIME_TO_HEARTBEAT = 30000;

export const WS_GET_TRANSACTIONS = getWsMessageForSend('transactions:new_transaction', 'phx_join');
export const WS_LEAVE_TRANSACTIONS = getWsMessageForSend(
  'transactions:new_transaction',
  'phx_leave',
);
export const WS_GET_BLOCK = getWsMessageForSend('blocks:new_block', 'phx_join');
export const WS_LEAVE_BLOCK = getWsMessageForSend('blocks:new_block', 'phx_leave');
