import { ReactComponent as GithubIcon } from 'src/assets/icons/ic-github.svg';
import { UiLink } from 'src/components/ui/UiLink';

export const SocialLink = () => (
  <UiLink
    to={'https://github.com/chunklimo'}
    target="_blank"
    rel="noopener noreferrer"
    sx={{ color: 'text.primary' }}
  >
    <GithubIcon />
  </UiLink>
);
