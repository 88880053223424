import { Box, Slider, Tooltip, Typography, useTheme } from '@mui/material';
import { getPositiveNumber } from 'src/utils/misc';
import { Iconify } from 'src/components/Iconify';

export enum UiStatsRangeVariant {
  neutral = 'neutral',
  negative = 'negative',
  positive = 'positive',
}

type RangeProps = {
  value: number;
  variant?: UiStatsRangeVariant;
  tooltip?: string;
};

export const UiStatsRange = ({
  value,
  variant = UiStatsRangeVariant.neutral,
  tooltip,
}: RangeProps) => {
  const theme = useTheme();

  const getColor = (variant: UiStatsRangeVariant) => {
    switch (variant) {
      case UiStatsRangeVariant.negative:
        return theme.palette.error.dark;
      case UiStatsRangeVariant.positive:
        return theme.palette.success.main;
      default:
        return theme.palette.grey[600];
    }
  };

  return (
    <Tooltip title={tooltip}>
      <Box
        sx={{ display: 'flex', alignItems: 'center', margin: '0 1.25rem', width: 'min-content' }}
      >
        <Slider
          sx={{
            '&.Mui-disabled': { color: getColor(variant) },
            '.MuiSlider-thumb': { display: 'none' },
            width: '3.125rem',
          }}
          disabled
          defaultValue={value}
          aria-label="Disabled slider"
        />
        <Typography marginLeft="0.625rem" color={getColor(variant)}>
          {getPositiveNumber(value)}%
        </Typography>
      </Box>
    </Tooltip>
  );
};

export enum UiTrendStatsVariant {
  negative = 'negative',
  positive = 'positive',
}

type TrendProps = {
  value: number;
  variant?: UiTrendStatsVariant;
};

export const UiStatsTrendNumber = ({
  value,
  variant = UiTrendStatsVariant.negative,
}: TrendProps) => {
  const theme = useTheme();

  const getColor = (variant: UiTrendStatsVariant) => {
    switch (variant) {
      case UiTrendStatsVariant.positive:
        return theme.palette.success.main;
      default:
        return theme.palette.error.dark;
    }
  };

  const getIcon = (variant: UiTrendStatsVariant) => {
    switch (variant) {
      case UiTrendStatsVariant.positive:
        return (
          <Iconify
            icon="noto-v1:red-triangle-pointed-down"
            width={'12px'}
            height={'12px'}
            sx={{
              color: theme.palette.success.main,
              transform: 'rotate(180deg)',
              marginRight: '0.25rem',

              '& svg, & path': {
                fill: theme.palette.success.main,
              },
            }}
          />
        );
      default:
        return (
          <Iconify
            icon="noto-v1:red-triangle-pointed-down"
            width={'12px'}
            height={'12px'}
            sx={{ marginRight: '0.25rem' }}
          />
        );
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {getIcon(variant)}
      <Typography sx={{ color: getColor(variant) }}>{getPositiveNumber(value)}%</Typography>
    </Box>
  );
};
