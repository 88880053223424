import { useMemo } from 'react';
import { ChainName } from 'src/types/Asset';
import { OracleType } from 'src/constants/oracle';
import { useCurrentFeedByOracleType } from './useCurrentFeedByOracleType';

export const useDataFeedForView = (
  currentNetwork: ChainName | undefined,
  oracleType: OracleType | undefined,
) => {
  const { currentFeed } = useCurrentFeedByOracleType(currentNetwork, oracleType);

  const dataFeedForView = useMemo(() => {
    if (!currentFeed) return null;

    const { verifying_contract, name, address } = currentFeed;

    return {
      network: verifying_contract ? verifying_contract.chain : (name as ChainName),
      feedAddress: verifying_contract ? verifying_contract.address : address,
    };
  }, [currentFeed]);

  return { dataFeedForView, currentFeed };
};
