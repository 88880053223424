import { useContext, useMemo } from 'react';
import { ChainName } from 'src/types/Asset';
import { OracleType } from 'src/constants/oracle';
import { AssetsCtx } from 'src/providers/AssetsProvider';
import { useDataFeedForView } from './useDataFeedForView';

export const useFeed = (
  currentNetwork: ChainName | undefined,
  oracleType: OracleType | undefined,
) => {
  const { feeds, isLoadingFeeds } = useContext(AssetsCtx);
  const { dataFeedForView, currentFeed } = useDataFeedForView(currentNetwork, oracleType);

  const feedForView = useMemo(() => {
    if (isLoadingFeeds) return;

    return feeds.find(
      (feed) =>
        feed.address === dataFeedForView?.feedAddress && feed.name === dataFeedForView.network,
    );
  }, [dataFeedForView, feeds, isLoadingFeeds]);

  return { feedForView, dataFeedForView, currentFeed };
};
