import { Box, Divider, Stack, Tooltip, Typography, alpha, styled, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import AceEditor from 'src/components/AceEditor';
import { CopyIcon } from 'src/components/CopyIcon';
import { Iconify } from 'src/components/Iconify';
import { UiCodeBlock } from 'src/components/ui/UiCodeBlock';
import { useCopyToClipboard } from 'src/hooks/useCopyToClipboard';
import { ApiContractInfo } from 'src/types/MultidataApi';

const ContractParamsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto minmax(0px, 1fr) auto minmax(0px, 1fr)',
  gridRowGap: '0.75rem',
  gridColumnGap: '2rem',

  [`${theme.breakpoints.down('md')}`]: {
    display: 'flex',
    flexDirection: 'column',
    gridRowGap: '0.25rem',
  },
}));

const ContractParam = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0.5rem 0',
  flexWrap: 'wrap',
  '& span': {
    color: theme.palette.grey[500],
  },
}));

export const VerifyCode = ({ contractInfo }: { contractInfo: ApiContractInfo }) => {
  const { copy } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();
  const { palette } = useTheme();

  function handleCopySourceCode(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    copy(String(contractInfo?.source_code));
    enqueueSnackbar('Source code copied!');
  }

  function handleCopyAbi(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    copy(String(JSON.stringify(contractInfo?.abi)));
    enqueueSnackbar('ABI code copied!');
  }

  return (
    <>
      <ContractParamsContainer>
        <ContractParam>
          <span>Contract name:</span>
        </ContractParam>
        <ContractParam>{contractInfo.name}</ContractParam>
        <ContractParam>
          <span>Optimization enabled:</span>
        </ContractParam>
        <ContractParam>{contractInfo.optimization_enabled ? 'Yes' : 'No'}</ContractParam>
        <ContractParam>
          <span>Compiler version:</span>
        </ContractParam>
        <ContractParam>{contractInfo.compiler_version}</ContractParam>
        <ContractParam>
          <span>Optimization runs:</span>
        </ContractParam>
        <ContractParam>{contractInfo.optimization_runs || 0}</ContractParam>
        <ContractParam>
          <span>EVM version:</span>
        </ContractParam>
        <ContractParam>{contractInfo.evm_version}</ContractParam>
        <ContractParam>
          <span>Verified at:</span>
        </ContractParam>
        <ContractParam>{contractInfo.verified_at}</ContractParam>
      </ContractParamsContainer>
      <Divider sx={{ mt: 4, mb: 6 }} />
      <Box mb={6}>
        <Stack mb={2} direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center">
            <Iconify icon="lucide:file-json-2" mr={1} color={alpha(palette.grey[500], 0.8)} />
            <Typography variant="subtitle1">{contractInfo.file_path}</Typography>
          </Stack>
          <Tooltip title="Copy source code to clipboard">
            <Box>
              <CopyIcon onCopy={handleCopySourceCode} />
            </Box>
          </Tooltip>
        </Stack>
        <AceEditor value={contractInfo.source_code} />
      </Box>
      <Box mb={6}>
        <Stack mb={2} direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center">
            <Iconify icon="lucide:file-json-2" mr={1} color={alpha(palette.grey[500], 0.8)} />
            <Typography variant="subtitle1">ABI</Typography>
          </Stack>
          <Tooltip title="Copy ABI to clipboard">
            <Box>
              <CopyIcon onCopy={handleCopyAbi} />
            </Box>
          </Tooltip>
        </Stack>
        <UiCodeBlock code={JSON.stringify(contractInfo.abi)} />
      </Box>
    </>
  );
};
