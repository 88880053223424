import { Box, BoxProps } from '@mui/material';
import { useState } from 'react';
import { Iconify } from 'src/components/Iconify';

export const NftImage = ({ imgUrl, ...props }: { imgUrl: string } & BoxProps) => {
  const [imageFailed, setImageFailed] = useState(false);

  if (imageFailed)
    return (
      <Box borderRadius="1rem" overflow="hidden" {...props}>
        <Iconify
          icon="ei:camera"
          width="100%"
          height="100%"
          padding={4}
          color={(theme) => theme.palette.grey[400]}
        />
      </Box>
    );

  return (
    <Box borderRadius="1rem" overflow="hidden" {...props}>
      <img
        src={imgUrl}
        onError={() => setImageFailed(true)}
        alt="Nft"
        width="100%"
        height="100%"
        style={{ objectFit: 'cover' }}
      />
    </Box>
  );
};
