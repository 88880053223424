import { Box, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { CONFIG } from 'src/app.config';
import { Iconify } from 'src/components/Iconify';
import { UiDivider, UiVerticalDivider } from 'src/components/ui/UiDivider';
import { UiGridContainer, UiGridItem } from 'src/components/ui/UiGrid';
import { UiIconTabButton } from 'src/components/ui/UiIconTabButton';
import { UiInfoTooltip } from 'src/components/ui/UiInfoTooltip';
import { ButtonLink, UiLink } from 'src/components/ui/UiLink';
import { UiStatsRange, UiStatsRangeVariant } from 'src/components/ui/UiStats';
import { useTimePast } from 'src/hooks/useTimePast';

import { ApiBlock } from 'src/types/MultidataApi';
import { spaceBetweenCell, wordBreak } from 'src/utils/cssStyles';
import { formatNumber } from 'src/utils/formatNumber';
import { jsonFetch } from 'src/utils/jsonFetch';
import { getPositiveNumber, numberWithCommas } from 'src/utils/misc';

const BlockPastTime = ({ block }: { block: ApiBlock }) => {
  const blockTimePast = useTimePast(block.timestamp);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <Iconify icon="ic:round-access-time" />
      <Typography ml="0.25rem">{blockTimePast}</Typography>
      <UiVerticalDivider />
      <Typography>{block.timestamp}</Typography>
    </Box>
  );
};

export const BlockStatistic = () => {
  const blockHeight = useParams().blockHeight as string;

  const [viewMoreDetails, setViewMoreDetails] = useState(false);
  const [block, setBlock] = useState<ApiBlock | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setIsLoading(true);
    jsonFetch<ApiBlock>(`${CONFIG.service.api}/blocks/${blockHeight}`)
      .then((block) => {
        setIsLoading(false);
        setErrorMessage('');
        setBlock(block);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.body.message) {
          setErrorMessage(err.body.message);
        } else {
          setErrorMessage('Something went wrong. Try refreshing the page or come back later.');
        }
        console.error('failed to get block information: ', err);
      });
  }, [blockHeight]);

  useEffect(() => {
    if (!viewMoreDetails) return;

    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  }, [viewMoreDetails]);

  if (isLoading) return <p>Loading...</p>;

  if (errorMessage.length > 0) return <p>{errorMessage}</p>;

  if (!block) return null;

  return (
    <UiGridContainer mt={2}>
      <UiGridItem sx={spaceBetweenCell}>
        <UiInfoTooltip title="The block height of a particular block is defined as the number of blocks preceding it in the blockchain" />
        <Typography>Block height</Typography>
      </UiGridItem>
      <UiGridItem>
        <Typography>{block.height}</Typography>
        <Box sx={{ display: 'flex', marginLeft: '1.5rem' }}>
          <RouterLink to={`/${CONFIG.routes.blockPage}/${+block.height - 1}`}>
            <Tooltip title="view previous block">
              <UiIconTabButton
                aria-label="previous block"
                disabled={+block.height === 0}
                sx={{ marginRight: '0.6rem' }}
              >
                <Iconify
                  sx={{ transform: 'rotate(-180deg)' }}
                  icon="material-symbols:arrow-forward-ios-rounded"
                />
              </UiIconTabButton>
            </Tooltip>
          </RouterLink>
          <RouterLink to={`/${CONFIG.routes.blockPage}/${+block.height + 1}`}>
            <Tooltip title="view next block">
              <UiIconTabButton aria-label="next block">
                <Iconify icon="material-symbols:arrow-forward-ios-rounded" />
              </UiIconTabButton>
            </Tooltip>
          </RouterLink>
        </Box>
      </UiGridItem>
      <UiGridItem sx={spaceBetweenCell}>
        <UiInfoTooltip title="Size of the block in bytes" />
        <Typography>Size</Typography>
        <UiGridItem display={{ xs: 'flex', md: 'none' }}>
          <Typography>{numberWithCommas(block.size)}</Typography>
        </UiGridItem>
      </UiGridItem>
      <UiGridItem display={{ xs: 'none', md: 'flex' }}>
        <Typography>{numberWithCommas(block.size)}</Typography>
      </UiGridItem>
      <UiGridItem sx={spaceBetweenCell}>
        <UiInfoTooltip title="Date & time at which block was produced." />
        <Typography>Timestamp</Typography>
      </UiGridItem>
      <UiGridItem>
        <BlockPastTime block={block} />
      </UiGridItem>
      <UiGridItem sx={spaceBetweenCell}>
        <UiInfoTooltip title="A block producer who successfully included the block onto the blockchain" />
        <Typography>Validated by</Typography>
      </UiGridItem>
      <UiGridItem>
        <UiLink to={`/${CONFIG.routes.addressPage}/${block.miner.hash}`} sx={wordBreak}>
          {block.miner.hash}
        </UiLink>
      </UiGridItem>
      <UiDivider />
      <UiGridItem sx={spaceBetweenCell}>
        <UiInfoTooltip title="The total gas amount used in the block and its percentage of gas filled in the block" />
        <Typography>Gas used</Typography>
      </UiGridItem>
      <UiGridItem>
        <Typography>{numberWithCommas(+block.gas_used)}</Typography>
        <UiStatsRange value={block.gas_used_percentage} />
      </UiGridItem>
      <UiGridItem sx={spaceBetweenCell}>
        <UiInfoTooltip title="Total gas limit provided by all transactions in the block" />
        <Typography>Gas limit</Typography>
        <UiGridItem display={{ xs: 'flex', md: 'none' }}>
          <Typography>{numberWithCommas(+block.gas_limit)}</Typography>
        </UiGridItem>
      </UiGridItem>
      <UiGridItem display={{ xs: 'none', md: 'flex' }}>
        <Typography>{numberWithCommas(+block.gas_limit)}</Typography>
      </UiGridItem>
      <UiGridItem sx={spaceBetweenCell}>
        <UiInfoTooltip title="Minimum fee required per unit of gas. Fee adjusts based on network congestion" />
        <Typography>Base fee per gas</Typography>
        <UiGridItem display={{ xs: 'flex', md: 'none' }}>
          <Typography>{formatNumber.getDisplay(block.base_fee_per_gas)}</Typography>
        </UiGridItem>
      </UiGridItem>
      <UiGridItem display={{ xs: 'none', md: 'flex' }}>
        <Typography>{formatNumber.getDisplay(block.base_fee_per_gas)}</Typography>
      </UiGridItem>
      <UiGridItem sx={spaceBetweenCell}>
        <UiInfoTooltip title="Amount burned from transactions included in the block. Equals Block Base Fee per Gas * Gas Used" />
        <Typography>Burnt fees</Typography>
      </UiGridItem>
      <UiGridItem>
        <Iconify icon="basil:fire-outline" />
        <Typography>{formatNumber.getDisplay(block.burnt_fees)}</Typography>
        {/* TODO: calculate correct value */}
        <UiStatsRange
          value={getPositiveNumber(block.burnt_fees_percentage ? block.burnt_fees_percentage : 0)}
          variant={UiStatsRangeVariant.positive}
          tooltip="Burnt fees / Txn fees * 100%"
        />
      </UiGridItem>
      <UiGridItem sx={spaceBetweenCell}>
        <UiInfoTooltip title="User-defined tips sent to validator for transaction priority/inclusion" />
        <Typography>Priority fee / Tip</Typography>
        <UiGridItem display={{ xs: 'flex', md: 'none' }}>
          <Typography>{formatNumber.getDisplay(block.priority_fee)}</Typography>
        </UiGridItem>
      </UiGridItem>
      <UiGridItem display={{ xs: 'none', md: 'flex' }}>
        <Typography>{formatNumber.getDisplay(block.priority_fee)}</Typography>
      </UiGridItem>
      <Box sx={{ gridColumn: 'span 2 / span 2', margin: '1rem 0' }}>
        <ButtonLink
          onClick={() => {
            setViewMoreDetails((isView) => !isView);
          }}
        >
          {viewMoreDetails ? 'Hide details' : 'View details'}
        </ButtonLink>
      </Box>
      {viewMoreDetails && (
        <>
          <UiGridItem sx={spaceBetweenCell}>
            <UiInfoTooltip title="Block difficulty for validator, used to calibrate block generation time" />
            <Typography>Difficulty</Typography>
          </UiGridItem>
          <UiGridItem>
            <Typography sx={wordBreak}>{block.difficulty}</Typography>
          </UiGridItem>
          <UiGridItem sx={spaceBetweenCell}>
            <UiInfoTooltip title="Total difficulty of the chain until this block" />
            <Typography>Total difficulty</Typography>
          </UiGridItem>
          <UiGridItem>
            <Typography sx={wordBreak}>{block.total_difficulty}</Typography>
          </UiGridItem>
          <UiDivider />
          <UiGridItem sx={spaceBetweenCell}>
            <UiInfoTooltip title="The SHA256 hash of the block" />
            <Typography>Hash</Typography>
          </UiGridItem>
          <UiGridItem>
            <Typography sx={wordBreak}>{block.hash}</Typography>
          </UiGridItem>
          <UiGridItem sx={spaceBetweenCell}>
            <UiInfoTooltip title="The hash of the block from which this block was generated" />
            <Typography>Parent hash</Typography>
          </UiGridItem>
          <UiGridItem>
            <UiLink to={`/${CONFIG.routes.blockPage}/${+block.height - 1}`} sx={wordBreak}>
              {block.parent_hash}
            </UiLink>
          </UiGridItem>
          <UiGridItem sx={spaceBetweenCell}>
            <UiInfoTooltip title="Block nonce is a value used during mining to demonstrate proof of work for a block" />
            <Typography>Nonce</Typography>
          </UiGridItem>
          <UiGridItem>
            <Typography sx={wordBreak}>{block.nonce}</Typography>
          </UiGridItem>
        </>
      )}
    </UiGridContainer>
  );
};
