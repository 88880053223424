import { useContext, useMemo } from 'react';
import { AssetsCtx } from 'src/providers/AssetsProvider';
import { ChainName } from 'src/types/Asset';

export const useNetworkValidators = (currentNetworkName: ChainName | undefined) => {
  const { validators, isLoadingValidators } = useContext(AssetsCtx);

  const networkValidators = useMemo(
    () =>
      currentNetworkName
        ? validators.filter((validator) => validator.chain_name === currentNetworkName)
        : null,
    [currentNetworkName, validators],
  );

  return { networkValidators, isLoadingValidators };
};
