declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    // default values
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    // custom values
    desktop: true;
  }
}

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    desktop: 1440,
    xl: 1536,
  },
};
