import { Box, styled, Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

export const UiDivider = styled(Box)(({ theme }) => ({
  gridColumn: 'span 2 / span 2',
  marginTop: '0.75rem',
  marginBottom: '0.75rem',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderColor: theme.palette.grey[300],
  width: '100%',
}));

export const UiVerticalDivider: FC<TypographyProps> = ({ sx, ...others }) => (
  <Typography
    component="span"
    sx={{ marginInlineStart: '0.75rem', marginInlineEnd: '0.75rem', ...sx }}
    {...others}
  >
    |
  </Typography>
);
