import { Box } from '@mui/material';
import { memo } from 'react';
import { FCC } from 'src/types/FCC';

import { StyledRootScrollbar, StyledScrollbar } from './styles';
import { ScrollbarProps } from './types';

export const Scrollbar: FCC<ScrollbarProps> = memo(({ children, sx, ...other }) => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  // TODO: use other version of isMobile
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <StyledRootScrollbar>
      <StyledScrollbar clickOnTrack={false} sx={sx} {...other}>
        {children}
      </StyledScrollbar>
    </StyledRootScrollbar>
  );
});
