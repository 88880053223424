import { useMemo, useState } from 'react';
import { COMPOUND, SLP, STABLECOINS, TREE_CRV, UNILP, YEARN } from 'src/constants/collaterals';
import { FiltersTypes, getFilters } from 'src/constants/filters';
import { AssetType } from 'src/types/Asset';
import { NetworkAssets } from './useNetworkAssets';

export const useFilterByType = (networkAssets?: NetworkAssets, currentTab?: AssetType) => {
  const [filterByType, setFilterByType] = useState<FiltersTypes>('All');

  const selectedAssets = useMemo(() => {
    if (!networkAssets) return [];

    return networkAssets[currentTab || 'all'] || [];
  }, [currentTab, networkAssets]);

  const filterByTypeOptions = useMemo(
    () =>
      getFilters(setFilterByType).map((el) => {
        if (!networkAssets || !selectedAssets) return el;
        let disable = false;

        if (el.label === 'Stable')
          disable = !selectedAssets.some((asset) => STABLECOINS.includes(asset.address));
        if (el.label === 'Uni V2 LP')
          disable = !selectedAssets.some((asset) => UNILP.includes(asset.address));
        if (el.label === 'Sushi LP')
          disable = !selectedAssets.some((asset) => SLP.includes(asset.address));
        if (el.label === 'Compound')
          disable = !selectedAssets.some((asset) => COMPOUND.includes(asset.address));
        if (el.label === 'Yearn Vaults')
          disable = !selectedAssets.some((asset) => YEARN.includes(asset.address));
        if (el.label === '3CRV')
          disable = !selectedAssets.some((asset) => TREE_CRV.includes(asset.address));

        return {
          ...el,
          disable,
        };
      }),
    [networkAssets, selectedAssets],
  );

  const filteredAssets = useMemo(() => {
    if (!networkAssets) return [];
    return selectedAssets.filter((asset) => {
      if (filterByType === 'All') return true;
      if (filterByType === 'Stable') return STABLECOINS.includes(asset.address);
      if (filterByType === 'Uni V2 LP') return UNILP.includes(asset.address);
      if (filterByType === 'Sushi LP') return SLP.includes(asset.address);
      if (filterByType === 'Compound') return COMPOUND.includes(asset.address);
      if (filterByType === 'Yearn Vaults') return YEARN.includes(asset.address);
      if (filterByType === '3CRV') return TREE_CRV.includes(asset.address);
      return (
        !STABLECOINS.includes(asset.address) &&
        !SLP.includes(asset.address) &&
        !UNILP.includes(asset.address) &&
        !COMPOUND.includes(asset.address) &&
        !YEARN.includes(asset.address) &&
        !TREE_CRV.includes(asset.address)
      );
    });
  }, [networkAssets, selectedAssets, filterByType]);

  return {
    filteredAssets,
    filterByTypeOptions,
    filterByType,
    setFilterByType,
  };
};
