import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useState } from 'react';
import { CONFIG } from 'src/app.config';
import { BlockStatistic } from 'src/components/blockDetails/BlockStatistic';
import { BlockTxs } from 'src/components/blockDetails/BlockTxs';
import { PageTitle } from 'src/components/PageTitle';
import { UiTabs } from 'src/components/ui/UiTabs';

const TABS = [
  {
    value: 'index',
    label: 'Details',
    component: <BlockStatistic />,
  },
  {
    value: 'transactions',
    label: 'Transactions',
    component: <BlockTxs disabledNotification />,
  },
] as const;

export const BlockDetails = () => {
  const blockHeight = useParams().blockHeight as string;

  const [tab, setTab] = useState('');

  return (
    <>
      <PageTitle
        title={`Block #${blockHeight}`}
        path={`/${CONFIG.routes.blocksPage}`}
        tooltip="Back to blocks list"
        mb={2}
      />
      <UiTabs tab={tab} setTab={setTab} tabs={TABS} />
      {TABS.map((el) => el.value === tab && <Box key={el.value}>{el.component}</Box>)}
    </>
  );
};
