import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Label } from 'src/components/label/Label';
import { UiCodeBlock } from 'src/components/ui/UiCodeBlock';
import { UiDivider } from 'src/components/ui/UiDivider';
import { UiHash } from 'src/components/UiAddress';
import { useCopyToClipboard } from 'src/hooks/useCopyToClipboard';
import { ApiContractLogsResp } from 'src/types/MultidataApi';
import { CopyIcon } from '../CopyIcon';
import { useFetch } from 'src/hooks/useFetch';
import { api } from 'src/api';
import { usePaginationWithSearchParams } from 'src/hooks/usePaginationWithSearchParams';
import { UiPagination } from 'src/components/ui/UiPagination';
import { UiGridContainer, UiGridItem } from 'src/components/ui/UiGrid';

export const AddressLogs = () => {
  const { address } = useParams();
  const { copy } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();
  const {
    fetchData,
    isLoading,
    data: contractLogs,
  } = useFetch<ApiContractLogsResp>('failed to get address counters: ');

  const { handlePageChange, page, paginationCount, prevPages, queryArgs } =
    usePaginationWithSearchParams(contractLogs?.next_page_params || null);

  const test = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (!address) return;
    fetchData(api.multidata.getAddressLogs(address, queryArgs ? `?${queryArgs}` : ''));
  }, [address, queryArgs]);

  function handleCopy(val: string) {
    copy(val);
    enqueueSnackbar('Copied!');
  }

  if (isLoading) return <p>Loading...</p>;
  if (!contractLogs || contractLogs?.items.length === 0)
    return <Typography>There are no logs for this address.</Typography>;

  return (
    <Box mt={4}>
      <UiPagination
        page={page}
        paginationCount={paginationCount}
        handlePageChange={handlePageChange}
        disabled={isLoading}
        emptyPrevPages={prevPages.length === 0}
        sx={{ marginBottom: '1.5rem' }}
      />
      {contractLogs.items.map((log, index) => (
        <React.Fragment key={`${log.tx_hash}${index}`}>
          <UiGridContainer>
            <UiGridItem>
              <Typography>Transaction</Typography>
            </UiGridItem>
            <UiGridItem>
              <UiHash hash={log.tx_hash} />
            </UiGridItem>

            <UiGridItem>
              <Typography>Topics</Typography>
            </UiGridItem>
            <UiGridItem>
              {log.topics.filter(Boolean).map((topic, i) => (
                <Stack key={`${topic}${i}`} mb={1} direction="row" alignItems="center">
                  <Label mr={1} ref={test}>
                    {i}
                  </Label>
                  <Typography sx={{ overflowWrap: 'anywhere' }}>{topic}</Typography>
                  <CopyIcon onCopy={() => handleCopy(topic)} />
                </Stack>
              ))}
            </UiGridItem>

            <UiGridItem>
              <Typography>Data</Typography>
            </UiGridItem>
            <UiGridItem>
              <UiCodeBlock code={log.data} sx={{ width: '100%', height: 'auto', py: 0 }} />
            </UiGridItem>
          </UiGridContainer>
          <UiDivider sx={{ my: 4 }} />
        </React.Fragment>
      ))}
    </Box>
  );
};
