import { useParams } from 'react-router-dom';
import { api } from 'src/api';
import { TxsTable } from 'src/components/TxsTable';

export const BlockTxs = ({ disabledNotification }: { disabledNotification?: boolean }) => {
  const blockHeight = useParams().blockHeight as string;

  const fetchTransactions = (queryString: string) =>
    api.multidata.getBlockTxs(blockHeight, queryString);

  return (
    <TxsTable
      disabledNotification={disabledNotification}
      fetchTransactions={fetchTransactions}
      title="Transactions"
      sx={{ mt: 0 }}
    />
  );
};
