import { Tooltip, TooltipProps, useTheme } from '@mui/material';
import { Iconify } from 'src/components/Iconify';

export const UiInfoTooltip = (props: Partial<TooltipProps>) => {
  const theme = useTheme();

  return (
    <Tooltip placement="top" {...(props as TooltipProps)}>
      <Iconify
        icon="mdi:information-box-outline"
        sx={{
          cursor: 'pointer',
          '&:hover': { color: theme.palette.primary.main },
        }}
      />
    </Tooltip>
  );
};
