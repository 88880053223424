import { Link, ListItemText } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { Iconify } from 'src/components/Iconify';
import { StyledIcon, StyledItem, StyledPopover } from 'src/components/nav/NavHorizontal/styles';
import { NavListProps, NavSectionProp } from 'src/components/nav/types';
import useActiveLink from 'src/hooks/useActiveLink';

interface Props {
  item: NavSectionProp;
}

export const NavHorizontalItem: FC<Props> = ({ item }) => {
  const navRef = useRef(null);
  const { pathname } = useLocation();

  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (item.path) {
      setActive(!!matchPath({ path: item.path, end: false }, pathname));
      return;
    }
    setActive(!!item.items?.find((el) => !!matchPath({ path: el.path, end: true }, pathname)));
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const props = item.isExternal && {
    target: '_blanck',
    rel: 'noopener noreferrer',
  };

  return (
    <>
      <Link component={RouterLink} to={item?.path || ''} underline="none" {...props}>
        <StyledItem
          depth={1}
          active={active}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          ref={navRef}
          sx={{ ml: 2 }}
        >
          <ListItemText
            primary={item.subheader}
            primaryTypographyProps={{
              noWrap: true,
              component: 'span',
              variant: active ? 'subtitle2' : 'body2',
            }}
          />

          {item.items && (
            <Iconify icon="eva:chevron-down-fill" width={16} sx={{ ml: 0.5, flexShrink: 0 }} />
          )}
        </StyledItem>
      </Link>
      {item.items && (
        <StyledPopover
          open={open}
          anchorEl={navRef.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          PaperProps={{
            onMouseEnter: handleOpen,
            onMouseLeave: handleClose,
          }}
          disableScrollLock
        >
          {item.items.map((el, i) => (
            <NavSubList key={`${el.title}-${i}`} item={el} />
          ))}
        </StyledPopover>
      )}
    </>
  );
};

type NavSubListProps = {
  item: NavListProps;
};

const NavSubList: FC<NavSubListProps> = ({ item }) => {
  const { title, path, icon } = item;

  const { active } = useActiveLink(path);

  return (
    <Link component={RouterLink} to={path} underline="none">
      <StyledItem depth={2} key={title + path} active={active} sx={{ p: 1 }}>
        {icon && <StyledIcon>{icon}</StyledIcon>}
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            noWrap: true,
            component: 'span',
            variant: active ? 'subtitle2' : 'body2',
          }}
        />
      </StyledItem>
    </Link>
  );
};
