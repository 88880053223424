import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { api } from 'src/api';
import { useFetch } from 'src/hooks/useFetch';
import { ApiTokensResp } from 'src/types/MultidataApi';
import TableHeadCustom from 'src/components/table/TableHeadCustom';
import { UiHash } from 'src/components/UiAddress';
import { UiInfo } from 'src/components/ui/UiInfo';
import { Filters } from 'src/components/Filters';
import { usePaginationWithSearchParams } from 'src/hooks/usePaginationWithSearchParams';
import { UiPagination } from 'src/components/ui/UiPagination';
import useResponsive from 'src/hooks/useResponsive';
import { PageTitle } from 'src/components/PageTitle';

const TABLE_HEAD_LABELS = [
  { id: 'rank', label: '', sx: { width: '1rem' } },
  { id: 'name', label: 'Name', sx: { width: '1rem' } },
  { id: 'address', label: 'Address', sx: { width: '1rem' } },
  { id: 'type', label: 'Type' },
  { id: 'holders', label: 'Holders' },
  { id: 'price', label: 'Price' },
  { id: 'marketCap', label: 'On-chain market cap' },
];

const filterSelect = {
  name: 'type',
  multiple: true,
  items: [
    {
      value: 'ERC-20',
      label: 'ERC-20',
    },
    {
      value: 'ERC-721',
      label: 'ERC-721',
    },
    {
      value: 'ERC-1155',
      label: 'ERC-1155',
    },
  ],
};

const filterString = {
  name: 'q',
  plaseholder: 'Token name or symbol',
};

export const Tokens = () => {
  const isMobile = useResponsive('down', 'md');
  const {
    fetchData,
    isLoading,
    txStatus,
    data: tokens,
  } = useFetch<ApiTokensResp>('failed to get tokens information: ');

  const { handlePageChange, page, paginationCount, prevPages, queryArgs, items_count } =
    usePaginationWithSearchParams(tokens?.next_page_params || null);

  useEffect(() => {
    fetchData(api.multidata.getTokens(queryArgs ? `?${queryArgs}` : ''));
  }, [queryArgs]);

  if (txStatus.error) return <p>{txStatus.error}</p>;

  return (
    <>
      <PageTitle title="Tokens" path="/" tooltip="Back to main page" mb={2} />
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={2}
        alignItems="flex-end"
        justifyContent="space-between"
        mb={3}
      >
        <Filters filterSelect={filterSelect} filterString={filterString} />
        <UiPagination
          page={page}
          paginationCount={paginationCount}
          handlePageChange={handlePageChange}
          disabled={isLoading}
          emptyPrevPages={prevPages.length === 0}
          sx={{ marginBottom: '1.5rem' }}
        />
      </Stack>
      <TableContainer
        sx={{
          '& th:first-of-type, td:first-of-type': {
            paddingLeft: 3,
          },
        }}
      >
        <Table stickyHeader sx={{ overflowX: 'scroll' }}>
          <TableHeadCustom headLabel={TABLE_HEAD_LABELS} />

          {isLoading ? (
            <TableBody>
              <tr>
                <td colSpan={TABLE_HEAD_LABELS.length}>
                  <p>Loading...</p>
                </td>
              </tr>
            </TableBody>
          ) : (
            <TableBody>
              {tokens?.items.map((token, index) => {
                const tokenIndex = items_count * (page - 1) + index + 1;

                return (
                  <TableRow key={token.address}>
                    <TableCell>
                      <Typography marginRight="2rem">{tokenIndex}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography whiteSpace="nowrap">{token.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <UiHash hash={token.address} cutting={isMobile} withCopy />
                    </TableCell>
                    <TableCell>
                      <UiInfo>{token.type}</UiInfo>
                    </TableCell>
                    <TableCell>
                      <Typography>{token.holders}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>-</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>-</Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
