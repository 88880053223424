import { createContext, useEffect, useRef, useState } from 'react';
import { CONFIG } from 'src/app.config';
import { TIME_TO_HEARTBEAT, TIME_TO_RECONNECT } from 'src/constants/websocket';
import { FCC } from 'src/types/FCC';

type WebsocketCtx = {
  addListener: (fn: (event: MessageEvent) => void) => void;
  removeListener: (fn: (event: MessageEvent) => void) => void;
  sendMessage: (message: string) => void;
  isReady: boolean;
};

export const WebsocketContext = createContext<WebsocketCtx>({
  addListener: () => {},
  removeListener: () => {},
  sendMessage: () => {},
  isReady: false,
});

export const WebsocketProvider: FCC = ({ children }) => {
  const [isReady, setIsReady] = useState(false);

  const ws = useRef<WebSocket | null>(null);

  const addListener = (fn: (event: MessageEvent) => void) => {
    if (!isReady) return;
    ws.current?.addEventListener('message', fn);
  };

  const removeListener = (fn: (event: MessageEvent) => void) => {
    if (!isReady) return;
    ws.current?.removeEventListener('message', fn);
  };

  const sendMessage = (message: string) => {
    if (!ws.current) return;

    const isWebsocketReady = ws.current.readyState === ws.current.OPEN;
    if (isWebsocketReady) {
      ws.current.send(message);
    }
  };

  useEffect(() => {
    let socket: WebSocket;
    let reconnectTimer: ReturnType<typeof setTimeout>;
    let heartbeatTimer: ReturnType<typeof setInterval>;

    const setHeartbeat = () =>
      (heartbeatTimer = setInterval(() => {
        console.log('[websocket]: send heartbeat');
        socket.send(JSON.stringify([null, null, 'phoenix', 'heartbeat', {}]));
      }, TIME_TO_HEARTBEAT));

    const openHandler = () => {
      console.log('[websocket]: open');
      setIsReady(true);
      setHeartbeat();
    };

    const closeHandler = () => {
      console.log('[websocket]: closed');
      setIsReady(false);
      window.clearInterval(heartbeatTimer);
      reconnectTimer = setTimeout(() => {
        console.log('[websocket]: trying to reconnect');
        connect();
      }, TIME_TO_RECONNECT);
    };

    const connect = () => {
      if (ws.current !== null) {
        window.clearTimeout(reconnectTimer);
        window.clearInterval(heartbeatTimer);
        ws.current.removeEventListener('open', openHandler);
        ws.current.removeEventListener('close', closeHandler);
        ws.current.close();
        ws.current = null;
      }

      socket = new WebSocket(CONFIG.service.wsUrl);
      socket.addEventListener('open', openHandler);
      socket.addEventListener('close', closeHandler);

      ws.current = socket;
    };

    connect();

    return () => {
      window.clearTimeout(reconnectTimer);
      window.clearInterval(heartbeatTimer);
      socket.removeEventListener('open', openHandler);
      socket.removeEventListener('close', closeHandler);
      socket.close();
      setIsReady(false);
      ws.current = null;
    };
  }, []);

  const ret = {
    addListener,
    removeListener,
    sendMessage,
    isReady,
  };

  return <WebsocketContext.Provider value={ret}>{children}</WebsocketContext.Provider>;
};
