import { useCallback, useEffect, useState } from 'react';
import { WS_GET_BLOCK, WS_LEAVE_BLOCK } from 'src/constants/websocket';
import { useWebsocket } from 'src/hooks/useWebsocket';
import { ApiBlock } from 'src/types/MultidataApi';

export const useNewBlock = () => {
  const [block, setBlock] = useState<ApiBlock | null>(null);

  const newBlockCallback = useCallback((event: any) => {
    const jsonResponse = JSON.parse(event.data);
    if (jsonResponse[3] === 'new_block') {
      setBlock(jsonResponse[4].block);
    }
  }, []);

  const { sendMessage, isReady } = useWebsocket(newBlockCallback);

  useEffect(() => {
    if (!isReady) return;

    sendMessage(WS_GET_BLOCK);

    return () => sendMessage(WS_LEAVE_BLOCK);
  }, [sendMessage, isReady]);

  return block;
};
