import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  alpha,
} from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ApiInternalTransactionsResp } from 'src/types/MultidataApi';
import TableHeadCustom from 'src/components/table/TableHeadCustom';
import { Iconify } from '../Iconify';
import { numberWithCommas } from 'src/utils/misc';
import { UiHash } from '../UiAddress';
import { Label } from '../label/Label';
import { api } from 'src/api';
import { useFetch } from 'src/hooks/useFetch';

export const InternalTransactions = () => {
  const { hash } = useParams();
  const {
    fetchData,
    isLoading,
    txStatus,
    data: trx,
  } = useFetch<ApiInternalTransactionsResp>('failed to get internal transactions information: ');

  useEffect(() => {
    if (!hash) return;
    fetchData(api.multidata.getInternalTxs(hash));
  }, [hash]);

  return (
    <div>
      {isLoading ? (
        <p>Loading...</p>
      ) : txStatus.error ? (
        <p>{txStatus.error}</p>
      ) : trx && trx.items.length > 0 ? (
        <TableContainer>
          <Table stickyHeader sx={{ overflowX: 'scroll' }}>
            <TableHeadCustom
              headLabel={[
                { id: 'status', label: 'Status', sx: { width: '6rem' } },
                { id: 'from', label: 'From' },
                { id: 'arrowIcon', label: '' },
                { id: 'to', label: 'To' },
                { id: 'value', label: 'Value', align: 'right', sx: { width: '12rem' } },
                { id: 'gasLimit', label: 'Gas limit', align: 'right', sx: { width: '12rem' } },
              ]}
            />
            <TableBody>
              {trx.items.map((tx, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Label
                      variant="soft"
                      color={tx.success ? 'success' : 'error'}
                      startIcon={
                        <Iconify
                          icon={
                            tx.success
                              ? 'clarity:success-standard-solid'
                              : 'material-symbols:error-rounded'
                          }
                          color={(theme) =>
                            tx.success ? theme.palette.success.main : theme.palette.error.main
                          }
                          mr="0.5rem"
                        />
                      }
                    >
                      {tx.success ? 'Success' : 'Error'}
                    </Label>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '1px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <UiHash
                      hash={tx.from.hash}
                      name={tx.from.name}
                      withCopy
                      cutting
                      cuttingOptions={[8, 8]}
                    />
                  </TableCell>
                  <TableCell sx={{ width: '1px' }}>
                    <Iconify
                      color={(theme) => alpha(theme.palette.grey[500], 0.8)}
                      width={30}
                      icon={'material-symbols:arrow-circle-right-outline-rounded'}
                    />
                  </TableCell>
                  <TableCell sx={{ width: '1px', whiteSpace: 'nowrap' }}>
                    <UiHash
                      hash={tx.to.hash}
                      name={tx.to.name}
                      withCopy
                      cutting
                      cuttingOptions={[8, 8]}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Typography>{tx.value}</Typography>
                  </TableCell>
                  <TableCell align="right" sx={{ width: '1px' }}>
                    <Typography>{numberWithCommas(+tx.gas_limit)}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>There are no internal transactions for this transaction.</Typography>
      )}
    </div>
  );
};
