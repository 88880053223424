import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { RHFCheckbox } from 'src/components/form/RHFCheckbox';
import { ConstructorArgumentsField } from 'src/components/form/fields/ConstructorArgumentsField';

export const FetchAutomaticallyField = () => {
  const { watch } = useFormContext();

  return (
    <>
      <Grid item xs={12} md={8}>
        <RHFCheckbox
          name="details.autodetectConstructorArgs"
          label="Try to fetch constructor arguments automatically"
        />
      </Grid>

      {!watch('details.autodetectConstructorArgs') && <ConstructorArgumentsField withText />}
    </>
  );
};
