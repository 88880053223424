import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { SOURCES } from 'src/constants/sources';
import { Q112 } from 'src/constants/staticValues';
import { MetricValuesSourcePricesDep } from 'src/types/MultidataV1Api';
import { BN } from 'src/utils/bignumber';
import { formatNumber } from 'src/utils/formatNumber';
import { numberWithDividers } from 'src/utils/number';

export const ValidatorDepShort: FC<{
  dep: MetricValuesSourcePricesDep;
  alias?: MetricValuesSourcePricesDep;
}> = ({ dep, alias }) => {
  if ('params' in dep) {
    const priceInUSD = BN(dep.price)
      .times(alias?.price || Q112)
      .div(Q112)
      .toString();

    return (
      <Grid item container spacing={2} xs={12} alignItems="center">
        <Grid
          item
          xs={4}
          sx={{
            display: 'flex',
            alignItems: 'center',
            svg: {
              flexShrink: 0,
              maxHeight: '20px',
              width: '20px',
              mr: 1,
              display: 'block',
            },
          }}
        >
          {SOURCES.find((el) => el.id === dep.source)?.icon}
          <Typography variant="subtitle2">{dep.source}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2">
            {numberWithDividers(formatNumber.getDisplayFromQ112(priceInUSD, 0, 4))}{' '}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2">{dep.params.pair}</Typography>
        </Grid>
      </Grid>
    );
  }

  // If no params in the current Dep, we look for one with params in its deps
  const firstDepWithParams = dep.deps?.find((nestedDep) => 'params' in nestedDep);
  const aliasForDep = dep.deps?.find((nestedDep) => nestedDep.operation === 'Alias');

  // If found, render it. Otherwise, return null.
  return (
    <>
      {firstDepWithParams && aliasForDep ? (
        <ValidatorDepShort dep={firstDepWithParams} alias={aliasForDep} />
      ) : (
        dep.deps.map((el, index) => <ValidatorDepShort key={`${index}-${el.operation}`} dep={el} />)
      )}
    </>
  );
};
