import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, Stack } from '@mui/material';

import { numberInputReg } from 'src/utils/input';
import { RHFCheckbox } from 'src/components/form/RHFCheckbox';
import RHFTextField from 'src/components/form/RHFTextField';

export const OptimizationField = ({ disabled }: { disabled?: boolean }) => {
  const { watch, setValue } = useFormContext();

  const onOptimazationRunsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue('details.optimizationRuns', numberInputReg(e.currentTarget.value));
  };

  return (
    <Grid item xs={12} md={8}>
      <Stack direction="row" spacing={2} alignItems="center">
        <RHFCheckbox
          name="details.withOptimization"
          label="Optimization enabled"
          style={{ whiteSpace: 'nowrap' }}
          disabled={disabled}
        />
        {watch('details.withOptimization') && (
          <RHFTextField
            onChange={onOptimazationRunsChange}
            name="details.optimizationRuns"
            disabled={disabled}
            style={{ width: '15rem' }}
            size="small"
            sx={{
              '& .MuiFormHelperText-root': {
                display: 'none',
              },
            }}
          />
        )}
      </Stack>
    </Grid>
  );
};
