import { Box, Stack } from '@mui/material';
import { useState } from 'react';
import { api } from 'src/api';
import { PageTitle } from 'src/components/PageTitle';
import { TxsTable } from 'src/components/TxsTable';
import { UiTabs } from 'src/components/ui/UiTabs';

const TABS = [
  {
    value: 'index',
    label: 'Validated',
    component: <TxsTable fetchTransactions={api.multidata.getTxs} />,
  },
  {
    value: 'pending',
    label: 'Pending',
    component: (
      <TxsTable
        fetchTransactions={() => api.multidata.getTxs('?filter=pending')}
        disabledNotification
      />
    ),
  },
] as const;

export const Transactions = () => {
  const [tab, setTab] = useState('');

  return (
    <>
      <Stack direction="row" spacing={4} mb={4}>
        <PageTitle title="Transactions" path="/" tooltip="Back to main page" />
        <UiTabs tab={tab} setTab={setTab} tabs={TABS} />
      </Stack>
      {TABS.map((el) => el.value === tab && <Box key={el.value}>{el.component}</Box>)}
    </>
  );
};
