import { AssetsApi } from 'src/types/Asset';
import { jsonFetch } from 'src/utils/jsonFetch';

async function getAssets(): Promise<AssetsApi> {
  return jsonFetch('https://api.metadata.fit/', {
    headers: {
      'Content-Type': 'text/plain',
    },
  });
}

async function getAssetsV2(): Promise<AssetsApi> {
  return jsonFetch('https://api.metadata.fit/v2', {
    headers: {
      'Content-Type': 'text/plain',
    },
  });
}

export const assets = {
  getAssets,
  getAssetsV2,
};
