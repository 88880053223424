import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { api } from 'src/api';
import { useFetch } from 'src/hooks/useFetch';
import { ApiContractInfo, ApiTokenCounters, ApiTokenInfo } from 'src/types/MultidataApi';
import { PageTitle } from 'src/components/PageTitle';
import { UiHash } from 'src/components/UiAddress';
import useResponsive from 'src/hooks/useResponsive';
import { UiGridContainer, UiGridItem } from 'src/components/ui/UiGrid';
import { UiInfoTooltip } from 'src/components/ui/UiInfoTooltip';
import { spaceBetweenCell } from 'src/utils/cssStyles';
import { UiDivider } from 'src/components/ui/UiDivider';
import { formatNumber } from 'src/utils/formatNumber';
import { numberWithDividers } from 'src/utils/number';
import { UiInfo } from 'src/components/ui/UiInfo';
import { CONFIG } from 'src/app.config';
import { TokenHolders } from 'src/components/tokenDetails/TokenHolders';
import { TokensTransfers } from 'src/components/TokensTransfers';
import { AddressContract } from 'src/components/pageAddress/AddressContract';
import { Iconify } from 'src/components/Iconify';
import { Inventory } from 'src/components/tokenDetails/Inventory';
import { UiTabs } from 'src/components/ui/UiTabs';

export const TokenDetails = () => {
  const address = useParams().address as string;
  const {
    isLoading,
    fetchData: fetchTokenInfo,
    data: tokenInfo,
  } = useFetch<ApiTokenInfo>('failed to get token information: ');

  const { fetchData: fetchCounters, data: tokenCounters } = useFetch<ApiTokenCounters>(
    'failed to get token counters: ',
  );

  const {
    fetchData: fetchContractInfo,
    data: contractInfo,
    isLoading: contractIsLoading,
  } = useFetch<ApiContractInfo>('failed to get contract info: ');

  const [tab, setTab] = useState('');

  const TABS = useMemo(() => {
    const isNft = tokenInfo?.type === 'ERC-721' || tokenInfo?.type === 'ERC-1155';
    const tabs = [
      {
        value: 'inventory',
        label: 'Inventory',
        component: <Inventory />,
        disabled: !isNft,
      },
      {
        value: 'transfers',
        label: 'Token transfers',
        component: (
          <TokensTransfers
            fetchTransfers={() => api.multidata.getTokenTransfers(address)}
            isNft={isNft}
          />
        ),
      },
      {
        value: 'holders',
        label: 'Holders',
        component: <TokenHolders />,
      },
      {
        value: 'contract',
        label: (
          <>
            Contract
            {contractInfo?.is_verified && (
              <Iconify
                pl={0.5}
                icon="clarity:success-standard-solid"
                color={(theme) => theme.palette.success.main}
              />
            )}
          </>
        ),
        component: <AddressContract contractInfo={contractInfo} isLoading={contractIsLoading} />,
      },
    ];
    return tabs;
  }, [address, contractInfo, contractIsLoading, tokenInfo?.type]);

  const isMobile = useResponsive('down', 'sm');

  useEffect(() => {
    if (!address) return;
    fetchTokenInfo(api.multidata.getTokenInfo(address));
    fetchCounters(api.multidata.getTokenCounters(address));
    fetchContractInfo(api.multidata.getContractInfo(address));
  }, [address]);

  if (isLoading) return <Typography>Loading...</Typography>;

  if (!tokenInfo || !tokenCounters) return null;

  const getDisplayTitle = (tokenInfo: ApiTokenInfo) => {
    return tokenInfo.name ? `${tokenInfo.name} (${tokenInfo.symbol}) token` : 'Token Details';
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mb: 2 }}>
        <PageTitle title={getDisplayTitle(tokenInfo)} path={`/${CONFIG.routes.tokensPage}`} />
        <Box sx={{ marginLeft: '0.75rem' }}>
          <UiInfo>{tokenInfo.type}</UiInfo>
        </Box>
      </Box>
      <UiGridContainer>
        <UiGridItem sx={spaceBetweenCell}>
          <UiInfoTooltip title="Token contract" />
          <Typography>Token contract</Typography>
        </UiGridItem>
        <UiGridItem>
          {address && (
            <Typography variant={'subtitle1'}>
              <UiHash
                variant="subtitle1"
                hash={address}
                cutting={isMobile}
                cuttingOptions={[21, 4]}
                isContract
                withCopy
              />
            </Typography>
          )}
        </UiGridItem>

        <UiGridItem sx={spaceBetweenCell}>
          <UiInfoTooltip title="The total amount of tokens issued" />
          <Typography>Max total supply</Typography>
        </UiGridItem>
        <UiGridItem>
          <Typography>
            {tokenInfo.total_supply
              ? numberWithDividers(
                  formatNumber.getDisplay(
                    tokenInfo.total_supply,
                    parseInt(tokenInfo.decimals || '0'),
                    4,
                    false,
                  ),
                )
              : 0}{' '}
            {tokenInfo.symbol}
          </Typography>
        </UiGridItem>
        <UiGridItem sx={spaceBetweenCell}>
          <UiInfoTooltip title="Number of accounts holding the token" />
          <Typography>Holders</Typography>
          <UiGridItem display={{ xs: 'flex', md: 'none' }}>
            <Typography>{numberWithDividers(tokenCounters.token_holders_count)}</Typography>
          </UiGridItem>
        </UiGridItem>
        <UiGridItem display={{ xs: 'none', md: 'flex' }}>
          <Typography>{numberWithDividers(tokenCounters.token_holders_count)}</Typography>
        </UiGridItem>
        <UiGridItem sx={spaceBetweenCell}>
          <UiInfoTooltip title="Number of transfer for the token" />
          <Typography>Transfers</Typography>
          <UiGridItem display={{ xs: 'flex', md: 'none' }}>
            <Typography>{numberWithDividers(tokenCounters.transfers_count)}</Typography>
          </UiGridItem>
        </UiGridItem>
        <UiGridItem display={{ xs: 'none', md: 'flex' }}>
          <Typography>{numberWithDividers(tokenCounters.transfers_count)}</Typography>
        </UiGridItem>

        {tokenInfo.decimals && (
          <>
            <UiGridItem sx={spaceBetweenCell}>
              <UiInfoTooltip title="Number of digits that come after the decimal place when displaying token value" />
              <Typography>Decimals</Typography>
              <UiGridItem display={{ xs: 'flex', md: 'none' }}>
                <Typography>{tokenInfo.decimals}</Typography>
              </UiGridItem>
            </UiGridItem>
            <UiGridItem display={{ xs: 'none', md: 'flex' }}>
              <Typography>{tokenInfo.decimals}</Typography>
            </UiGridItem>
          </>
        )}

        <UiGridItem sx={spaceBetweenCell}>
          <UiInfoTooltip title="Type of token" />
          <Typography>Type</Typography>
          <UiGridItem display={{ xs: 'flex', md: 'none' }}>
            <Typography>{tokenInfo.type}</Typography>
          </UiGridItem>
        </UiGridItem>
        <UiGridItem display={{ xs: 'none', md: 'flex' }}>
          <Typography>{tokenInfo.type}</Typography>
        </UiGridItem>
      </UiGridContainer>
      <UiDivider />
      <UiTabs tab={tab} setTab={setTab} tabs={TABS} sx={{ mb: 6 }} />
      {TABS.map((el) => el.value === tab && <Box key={el.value}> {el.component} </Box>)}
    </Box>
  );
};
