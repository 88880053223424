import { FC } from 'react';
import { Grid, Typography } from '@mui/material';

import { CompilerField } from 'src/components/form/fields/CompilerField';
import { EVMversionField } from './fields/EVMversionField';
import { UploadField } from './fields/UploadField';

export const VyperMultipartForm: FC<{
  evmVersions?: string[];
  compilers?: string[];
  disabled?: boolean;
}> = ({ evmVersions, compilers, disabled }) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ color: 'text.disabled', mb: 1 }}>
          Contract verification via Vyper (multi-part files)
        </Typography>
      </Grid>
      <CompilerField compilers={compilers} disabled={disabled} />
      <EVMversionField versions={evmVersions} disabled={disabled} />
      <UploadField
        text="Upload all Vyper contract source files."
        buttonLabel="Sources *.vy files"
        accept=".vy"
        disabled={disabled}
      />
    </>
  );
};
