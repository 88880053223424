import { Box, Button, Grid, IconButton, Stack, Typography, alpha, useTheme } from '@mui/material';
import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { Iconify } from 'src/components/Iconify';
import { formatFileSize } from 'src/utils/formatFileSize';

type Props = {
  text: string;
  buttonLabel: string;
  accept: string;
  disabled?: boolean;
};

export const UploadField = ({ text, buttonLabel, accept, disabled }: Props) => {
  const { watch, setValue, formState } = useFormContext();
  const theme = useTheme();

  const onRemove = (file: File) => {
    setValue(
      'details.files',
      [...watch('details.files')].filter((item) => item !== file),
    );
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    setValue('details.files', Array.from([...watch('details.files'), ...e.target.files]));
  };

  return (
    <>
      <Grid item xs={12} md={8}>
        <Box
          textAlign="center"
          style={{
            padding: theme.spacing(5),
            borderRadius: theme.shape.borderRadius,
            transition: theme.transitions.create('padding'),
            backgroundColor: theme.palette.background.neutral,
            border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
              mb: 1,
            }}
          >
            {buttonLabel}
          </Typography>
          <Button variant="contained" component="label" disabled={disabled}>
            Select files
            <input hidden accept={accept} multiple type="file" onChange={handleChange} />
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography
          variant="body2"
          sx={{
            color: 'text.secondary',
          }}
        >
          {text}
        </Typography>
      </Grid>

      <Grid item xs={12} md={8}>
        <MultiFilePreview files={[...watch('details.files')]} onRemove={onRemove} />
        {formState.errors?.root?.files && (
          <Box>
            <Typography
              variant="caption"
              sx={{
                color: (theme) => theme.palette.error.main,
              }}
            >
              {formState.errors.root.files.message?.toString()}
            </Typography>
          </Box>
        )}
      </Grid>
    </>
  );
};

function MultiFilePreview({ files, onRemove }: { files: File[]; onRemove: (file: File) => void }) {
  if (!files?.length) {
    return null;
  }

  return (
    <>
      {files.map((file, index) => (
        <Stack
          key={index}
          direction="row"
          alignItems="center"
          display="inline-flex"
          justifyContent="center"
          spacing={2}
          sx={{
            m: 0.5,
            p: 2,
            height: 80,
            borderRadius: 1.25,
            overflow: 'hidden',
            position: 'relative',
            border: (theme) => `solid 1px ${theme.palette.divider}`,
          }}
        >
          <Iconify icon="gala:file-document" style={{ transform: 'scale(2)' }} />
          <Stack>
            <Typography
              variant="caption"
              sx={{
                color: 'text.secondary',
              }}
            >
              {file.name}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: 'text.secondary',
              }}
            >
              {formatFileSize(file.size, 0)}
            </Typography>
          </Stack>
          {onRemove && (
            <IconButton
              size="small"
              onClick={() => onRemove(file)}
              sx={{
                top: 4,
                right: 4,
                p: '1px',
                position: 'absolute',
              }}
            >
              <Iconify icon="eva:close-fill" width={16} />
            </IconButton>
          )}
        </Stack>
      ))}
    </>
  );
}
