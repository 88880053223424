import { useContractChainMap } from 'src/providers/ContractChainMapProvider';
import { Label } from './label/Label';
import { useMemo } from 'react';
import { useLinkedTx } from 'src/hooks/useLinkedTx';
import { ApiTransaction } from 'src/types/MultidataApi';
import { CHAINS } from 'src/constants/chains';
import { Link, Stack } from '@mui/material';
import { shortenHash } from 'src/utils/ui';
import { LabelColor } from './label/types';

export const NetworkLabel = ({ tx, color }: { tx: ApiTransaction; color: LabelColor }) => {
  const { getWriteChain, getWriteChainId } = useContractChainMap();
  const writeChain = getWriteChain(tx?.to?.hash);
  const writeChainId = getWriteChainId(tx?.to?.hash);

  const linkedTx = useLinkedTx(writeChainId, tx?.hash);
  const chain = useMemo(() => CHAINS.find((chain) => chain.id === writeChainId), [writeChainId]);

  if (!writeChain) return null;

  return (
    <Stack direction="row" spacing={1}>
      <Label variant="filled" color={color}>
        {writeChain}
      </Label>
      {chain && linkedTx && (
        <Link
          href={`${chain.explorer}/tx/${linkedTx.hash}`}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ cursor: 'pointer' }}
        >
          {shortenHash(linkedTx.hash, 4, 4)}
        </Link>
      )}
    </Stack>
  );
};
