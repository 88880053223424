import { useContext, useEffect } from 'react';
import { WebsocketContext } from 'src/providers/WebsocketProvider';

export const useWebsocket = (listener?: (event: MessageEvent) => void) => {
  const { addListener, removeListener, sendMessage, isReady } = useContext(WebsocketContext);

  useEffect(() => {
    if (!isReady) return;

    if (listener) {
      addListener(listener);
    }

    return () => {
      if (listener) {
        removeListener(listener);
      }
    };
  }, [isReady]);

  return {
    sendMessage,
    isReady,
    unsubscribe: listener ? () => removeListener(listener) : undefined,
  };
};
