import { createContext, useEffect, useMemo, useState } from 'react';
import { api } from 'src/api';
import { useFetch } from 'src/hooks/useFetch';
import { FCC } from 'src/types/FCC';
import { MulV1Feed, MulV1Validator } from 'src/types/MultidataV1Api';
import { RemoteData } from 'src/utils/remoteData';

interface AssetsCtxProps {
  feeds: MulV1Feed[];
  feedsTxStatus: RemoteData<unknown, null, any> | null;
  isLoadingFeeds: boolean;
  isLoadingValidators: boolean;
  participants: Record<string, MulV1Validator>;
  validators: MulV1Validator[];
}

export const AssetsCtx = createContext<AssetsCtxProps>({
  feeds: [],
  feedsTxStatus: null,
  isLoadingValidators: false,
  isLoadingFeeds: false,
  participants: {},
  validators: [],
});

export const AssetsCtxProvider: FCC = ({ children }) => {
  const {
    fetchData: fetchFeeds,
    data: feeds,
    isLoading: isLoadingFeeds,
    txStatus: feedsTxStatus,
  } = useFetch<MulV1Feed[]>('failed to get feeds list: ');

  const {
    fetchData: fetchValidators,
    data: validators,
    isLoading: isLoadingValidators,
  } = useFetch<MulV1Validator[]>('failed to get validators for chain: ');

  const participants = useMemo(() => {
    const validatorsMap = {} as Record<string, MulV1Validator>;
    validators?.forEach((validator) => (validatorsMap[validator.address] = validator));

    return validatorsMap;
  }, [validators]);

  useEffect(() => {
    fetchFeeds(api.multidataV1.getFeeds());
    fetchValidators(api.multidataV1.getValidators());
  }, []);

  return (
    <AssetsCtx.Provider
      value={{
        feeds: feeds || [],
        feedsTxStatus,
        isLoadingFeeds,
        isLoadingValidators,
        participants,
        validators: validators || [],
      }}
    >
      {children}
    </AssetsCtx.Provider>
  );
};
