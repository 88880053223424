import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Typography, Grid } from '@mui/material';

import { RHFCheckbox } from 'src/components/form/RHFCheckbox';
import { CompilerField } from 'src/components/form/fields/CompilerField';
import { NightlyBuildsField } from 'src/components/form/fields/NightlyBuildsField';
import { LibrariesField } from 'src/components/form/fields/LibrariesField';
import { EVMversionField } from 'src/components/form/fields/EVMversionField';
import { ContractCodeField } from 'src/components/form/fields/ContractCodeField';
import { OptimizationField } from 'src/components/form/fields/OptimizationField';

export const SolidityFlattenedForm: FC<{
  evmVersions?: string[];
  compilers?: string[];
  disabled?: boolean;
}> = ({ evmVersions, compilers, disabled }) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue('details.autodetectConstructorArgs', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ color: 'text.disabled', mb: 1 }}>
          Contract verification via Solidity (standard JSON input)
        </Typography>
      </Grid>

      <Grid item xs={12} md={8}>
        <RHFCheckbox name="details.isYulContract" label="Is Yul contract" disabled={disabled} />
      </Grid>

      <NightlyBuildsField disabled={disabled} />

      <CompilerField compilers={compilers} withText disabled={disabled} />

      <EVMversionField versions={evmVersions} disabled={disabled} />

      <OptimizationField disabled={disabled} />

      <ContractCodeField withText disabled={disabled} />

      <LibrariesField />
    </>
  );
};
