import moment from 'moment';
import { useEffect, useState } from 'react';

export const useTimePast = (from: number | string) => {
  const [pastTime, setPastTime] = useState('0');

  useEffect(() => {
    calculatePastTime();
    const interval = setInterval(() => {
      calculatePastTime();
    }, 1000);

    return () => clearInterval(interval);
  }, [from]);

  function calculatePastTime() {
    const cur = moment().utc();
    const last = moment(from);
    const diffMonth = cur.diff(last, 'month');
    last.add(diffMonth, 'month');
    const diffDays = cur.diff(last, 'days');
    last.add(diffDays, 'days');
    const diffHours = cur.diff(last, 'hours');
    last.add(diffHours, 'hours');
    const diffMin = cur.diff(last, 'minutes');
    last.add(diffMin, 'hours');
    const diffSec = cur.diff(last, 'second');

    setPastTime(
      `${!!diffMonth ? `${diffMonth} month ` : ''}${!!diffDays ? `${diffDays} days ` : ''}${
        !!diffHours ? `${diffHours} hours ` : ''
      }${!!diffMin ? `${diffMin} min ` : `${diffSec} sec`}`,
    );
  }

  return pastTime;
};
