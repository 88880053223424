import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { api } from 'src/api';
import TableHeadCustom from 'src/components/table/TableHeadCustom';
import { useFetch } from 'src/hooks/useFetch';
import { ApiTokenHoldersResp } from 'src/types/MultidataApi';
import { UiHash } from 'src/components/UiAddress';
import { formatNumber } from 'src/utils/formatNumber';

export const TokenHolders = () => {
  const { address } = useParams();
  const {
    fetchData,
    isLoading,
    txStatus,
    data: holders,
  } = useFetch<ApiTokenHoldersResp>('failed to get token holders: ');

  useEffect(() => {
    if (!address) return;
    fetchData(api.multidata.getTokenHolders(address));
  }, [address]);

  if (isLoading) return <p>Loading...</p>;

  if (txStatus.error) return <p>{txStatus.error}</p>;

  if (!holders) return null;

  return (
    <>
      {holders.items.length > 0 ? (
        <TableContainer>
          <Table stickyHeader sx={{ overflowX: 'scroll' }}>
            <TableHeadCustom
              headLabel={[
                { id: 'holder', label: 'Holder' },
                { id: 'quantity', label: 'Quantity' },
              ]}
            />
            <TableBody>
              {holders.items.map((holder, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ width: '1px' }}>
                    <UiHash hash={holder.address.hash} withCopy />
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatNumber.getDisplay(
                        holder.value,
                        parseInt(holder.token.decimals || '0'),
                        4,
                        false,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>There are no token holders for this address.</Typography>
      )}
    </>
  );
};
