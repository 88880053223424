import {
  Card,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { ApiLog } from 'src/types/MultidataApi';
import TableHeadCustom from '../table/TableHeadCustom';
import { UiHash } from '../UiAddress';
import useResponsive from 'src/hooks/useResponsive';
import { UiCodeBlock } from 'src/components/ui/UiCodeBlock';

type ViewType = 'hex' | 'dec';

export const LogData = ({ log }: { log: ApiLog }) => {
  const theme = useTheme();
  const isMobile = useResponsive('down', 'md');

  const [dataView, setDataView] = useState<ViewType>(log.decoded ? 'dec' : 'hex');
  const handleChangeView = (e: SelectChangeEvent) => {
    setDataView(e.target.value as ViewType);
  };

  return (
    <>
      {log.decoded && (
        <Select
          value={dataView}
          onChange={handleChangeView}
          sx={{
            maxWidth: '6.2rem',
            marginBottom: '0.5rem',
            '& .MuiInputBase-input': { padding: '4px 6px' },
          }}
        >
          <MenuItem value={'hex'}>Hex</MenuItem>
          <MenuItem value={'dec'}>Dec</MenuItem>
        </Select>
      )}

      {dataView === 'dec' ? (
        <Card
          sx={{
            background: theme.palette.background.neutral,
            boxShadow: 'none',
            borderRadius: '0.5rem',
            width: '100%',
            overflowX: 'auto',
            p: '1rem 0.5rem',
            th: {
              background: 'inherit',
            },
          }}
        >
          <Table stickyHeader sx={{ overflowX: 'scroll', pb: 1 }}>
            <TableHeadCustom
              headLabel={[
                { id: 'name', label: 'Name', sx: { width: '12rem', maxWidth: '40%' } },
                { id: 'type', label: 'Type', sx: { width: '6rem' } },
                { id: 'indexed', label: 'Indexed?', sx: { width: '6rem' } },
                { id: 'data', label: 'Data' },
              ]}
            />
            <TableBody>
              {log.decoded?.parameters.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography>{item.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{item.type}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{item.indexed.toString()}</Typography>
                  </TableCell>
                  <TableCell>
                    {item.type === 'address' ? (
                      <UiHash hash={item.value} cutting={isMobile} variant="body1" withCopy />
                    ) : (
                      <Typography>{item.value}</Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      ) : (
        <UiCodeBlock code={log.data} sx={{ width: '100%', height: 'auto', py: 0 }} />
      )}
    </>
  );
};
